import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-box-tutti-1',
  Jobs = 'msite-middle-lp-jobs-1',
  Motor = 'msite-middle-lp-motor-1',
  Market = 'msite-middle-lp-market-1',
  RealEstate = 'msite-middle-lp-realestate-1',
}

const msiteMiddle1Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_TUTTI,
    channel: 'MSITE-MIDDLE-LP-TUTTI-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_JOBS,
    channel: 'MSITE-MIDDLE-LP-JOBS-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MOTORS,
    channel: 'MSITE-MIDDLE-LP-MOTORS-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MARKET,
    channel: 'MSITE-MIDDLE-LP-MARKET-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_REALESTATE,
    channel: 'MSITE-MIDDLE-LP-REALESTATE-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1: Array<ShortConfiguration> = [
  msiteMiddle1Default,
  msiteMiddle1Job,
  msiteMiddle1Motor,
  msiteMiddle1Market,
  msiteMiddle1RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { msiteMiddle1, variations as MsiteMiddle1 };
