import type { IncomingMessage } from 'http';

const mobileMatcher =
  /^.*(\(iP[oh]|Android(?![^)]*?(SM-T|GT-P))|IEMobile\/(1[1-9]|[2-9]\d+)).*$/;

function isMobile(UA: string): boolean {
  return mobileMatcher.test(UA);
}

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

export function getDeviceType(req?: IncomingMessage): DeviceType | null {
  if (!req) {
    return null;
  }

  const akamaiDeviceType = req.headers['x-subito-device'];
  if (akamaiDeviceType) {
    return akamaiDeviceType as DeviceType;
  }

  return isMobile(req.headers['user-agent'] ?? '') ? 'mobile' : 'desktop';
}
