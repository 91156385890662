import type { SubitoAction } from '@reducers/common';
import type { Reducer } from 'redux';

const defaultState: EnvState = {
  id: null,
};

const env: Reducer<EnvState, EnvActions> = (
  state = defaultState,
  action
): EnvState => {
  switch (action.type) {
    case ActionTypes.UPDATE_ENVIRONMENT_ID: {
      const newState = {
        ...state,
        id: action.payload,
      };

      return newState;
    }
    default:
      return state;
  }
};

enum ActionTypes {
  ADD_VARIABLE = 'add env variable',
  UPDATE_ENVIRONMENT_ID = 'update environment id',
}

type EnvActions = ReturnType<typeof updateEnvironmentId>;

function updateEnvironmentId(
  id: string
): SubitoAction<ActionTypes.UPDATE_ENVIRONMENT_ID, string> {
  return {
    type: ActionTypes.UPDATE_ENVIRONMENT_ID,
    payload: id,
  };
}

interface EnvState {
  id: string | null;
}

export { env, updateEnvironmentId };
export type { EnvState };
