import { LazyVideoPreroll } from '@client/components/Adv/GPT/sdkIntegration';
import { newAdvEndedPromise } from '@client/components/Adv/GPT/useAdvDetails';
import React, { useEffect } from 'react';

export const VideoPreroll: React.FC<{
  containerRef: React.RefObject<HTMLElement>;
}> = ({ containerRef }) => {
  const [component, setComponent] = React.useState<React.JSX.Element | null>(
    null
  );
  useEffect(() => {
    (async () => {
      await newAdvEndedPromise;
      setComponent(<LazyVideoPreroll parentRef={containerRef} />);
    })();
  }, [containerRef]);

  return component;
};
