import { Reducer } from 'redux';
import { PublicUser } from '@shared/models';
import { LoginTemplate } from '@sbt-web/auth';
import { dispatchAsyncEvent } from '@tools/eventHelpers';
import { SubitoAction } from '@reducers/common';

const defaultState: UserState = {
  logged: false,
  dialogOpened: false,
};

export const user: Reducer<UserState, UserActions> = (
  state = defaultState,
  action
): UserState => {
  switch (action.type) {
    case Types.SAVE_USER_DATA: {
      return {
        ...state,
        logged: true,
        data: action.payload,
      };
    }
    case Types.SET_DIALOG_INFO:
      dispatchAsyncEvent(`subito:listingCovered:${action.payload.opened}`);
      return {
        ...state,
        dialogOpened: action.payload.opened,
        dialogTemplate: action.payload.template || state.dialogTemplate,
        onLoginCallback: action.payload.callback || state.onLoginCallback,
      };
    default:
      return state;
  }
};

enum Types {
  SAVE_USER_DATA = 'save user data',
  SET_DIALOG_INFO = 'set user dialog info',
}

type UserActions =
  | ReturnType<typeof saveUserData>
  | ReturnType<typeof setDialogInfo>;

export function saveUserData(
  data: PublicUser
): SubitoAction<Types.SAVE_USER_DATA, PublicUser> {
  return {
    type: Types.SAVE_USER_DATA,
    payload: data,
  };
}

interface SetDialogInfoPayload {
  opened: boolean;
  template?: LoginTemplate;
  callback?: (user: PublicUser) => void;
}

export function setDialogInfo(
  opened: boolean,
  template?: LoginTemplate,
  callback?: (user: PublicUser) => void
): SubitoAction<Types.SET_DIALOG_INFO, SetDialogInfoPayload> {
  return {
    type: Types.SET_DIALOG_INFO,
    payload: {
      opened,
      template,
      callback,
    },
  };
}

export interface UserState {
  logged: boolean;
  data?: PublicUser;
  dialogOpened: boolean;
  dialogTemplate?: LoginTemplate;
  onLoginCallback?: (user: PublicUser) => void;
}
