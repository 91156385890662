import { createContext } from 'react';
import { SkyscraperData } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';

export type AdvContextType = {
  skyscraperData: SkyscraperData;
};
const AdvContext = createContext<AdvContextType>({
  skyscraperData: {},
});

export { AdvContext };
