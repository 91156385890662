import { default as brandValuesToName } from './brand-from-param';
import { default as genderValuesToName } from './gender-from-param';
import { default as typologyValuesToName } from './typology-from-param';

const parametersToRedirect = ['clt', 'fbrnd', 'clg'];

const parameterToValue = new Map<string, Map<string, string>>([
  ['clt', typologyValuesToName],
  ['fbrnd', brandValuesToName],
  ['clg', genderValuesToName],
]);

export {
  brandValuesToName,
  genderValuesToName,
  typologyValuesToName,
  parameterToValue,
  parametersToRedirect,
};
