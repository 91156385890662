export default new Map<string, string>([
  ['000000', 'altro'],
  ['000001', '100-carati'],
  ['000002', '2jewels'],
  ['000003', '8848'],
  ['000004', 'abercrombie-fitch'],
  ['000005', 'ac-milan'],
  ['000006', 'accessorize'],
  ['000007', 'acciaio'],
  ['000008', 'acerbis'],
  ['000009', 'acqua-di-parma'],
  ['000010', 'add'],
  ['000011', 'addis-dive'],
  ['000012', 'adidas'],
  ['000013', 'aeronautica-militare'],
  ['000014', 'aimee'],
  ['000015', 'air-jordan'],
  ['000016', 'aku'],
  ['000017', 'akyta'],
  ['000018', 'al-iver'],
  ['000019', 'albano'],
  ['000020', 'alberta-ferretti'],
  ['000021', 'alberto-guardiani'],
  ['000022', 'alcott'],
  ['000023', 'aldo'],
  ['000024', 'alejandro'],
  ['000025', 'alesya'],
  ['000026', 'alexander'],
  ['000027', 'alexander-mcqueen'],
  ['000028', 'alice-pi'],
  ['000029', 'alitalia'],
  ['000030', 'alpha-industries'],
  ['000031', 'alpinestars'],
  ['000032', 'alta-tensione'],
  ['000033', 'altanus'],
  ['000034', 'altramarea'],
  ['000035', 'alviero-martini'],
  ['000036', 'alviero-rodriguez'],
  ['000037', 'amazfit'],
  ['000038', 'american-tourister'],
  ['000039', 'americano'],
  ['000040', 'amiri'],
  ['000041', 'amouage'],
  ['000042', 'anastasya'],
  ['000043', 'angelico'],
  ['000044', 'aniye-by'],
  ['000045', 'annabella'],
  ['000046', 'annfield'],
  ['000047', 'anonyme'],
  ['000048', 'ansia-latex'],
  ['000049', 'antica-murrina'],
  ['000050', 'antiet'],
  ['000051', 'antony-morato'],
  ['000052', 'apple'],
  ['000053', 'aquascutum'],
  ['000054', 'arcaplanet'],
  ['000055', 'arelier'],
  ['000056', 'arena'],
  ['000057', 'armani'],
  ['000058', 'armani-exchange'],
  ['000059', 'armani-jeans'],
  ['000060', 'arrow'],
  ['000061', 'artengo'],
  ['000062', 'artigli'],
  ['000063', 'as-roma'],
  ['000064', 'ascott'],
  ['000065', 'ash'],
  ['000066', 'asics'],
  ['000067', 'asolo'],
  ['000068', 'asos'],
  ['000069', 'aspesi'],
  ['000070', 'astrolabio'],
  ['000071', 'atelier'],
  ['000072', 'atlantic-stars'],
  ['000073', 'attore'],
  ['000074', 'audemars-piguet'],
  ['000075', 'australia'],
  ['000076', 'autry'],
  ['000077', 'avi-8'],
  ['000078', 'avia'],
  ['000079', 'avirex'],
  ['000080', 'avon'],
  ['000081', 'axa'],
  ['000082', 'axo'],
  ['000083', 'ayitoo'],
  ['000084', 'azzaro'],
  ['000085', 'babyliss'],
  ['000086', 'baccarat'],
  ['000087', 'bacheta'],
  ['000088', 'baci-abbracci'],
  ['000089', 'bagutta'],
  ['000090', 'bailo'],
  ['000091', 'baldinini'],
  ['000092', 'bale'],
  ['000093', 'balenciaga'],
  ['000094', 'balloon'],
  ['000095', 'bally'],
  ['000096', 'balmain'],
  ['000097', 'bape'],
  ['000098', 'barbour'],
  ['000099', 'bark'],
  ['000100', 'baronessa'],
  ['000101', 'base'],
  ['000102', 'basile'],
  ['000103', 'bassetti'],
  ['000104', 'bata'],
  ['000105', 'batman'],
  ['000106', 'battois'],
  ['000107', 'baume-mercier'],
  ['000108', 'bayern-monaco'],
  ['000109', 'befast'],
  ['000110', 'belfe'],
  ['000111', 'belmonte'],
  ['000112', 'belstaff'],
  ['000113', 'ben-sherman'],
  ['000114', 'benedettino'],
  ['000115', 'benetton'],
  ['000116', 'beretta'],
  ['000117', 'berik'],
  ['000118', 'bering'],
  ['000119', 'bershka'],
  ['000120', 'berta'],
  ['000121', 'best-company'],
  ['000122', 'beta'],
  ['000123', 'bianca-brandi'],
  ['000124', 'bikkembergs'],
  ['000125', 'billabong'],
  ['000126', 'bing'],
  ['000127', 'birkenstock'],
  ['000128', 'blackglama'],
  ['000129', 'blancpain'],
  ['000130', 'blauer'],
  ['000131', 'bliss'],
  ['000132', 'blu-donna'],
  ['000133', 'bluespirit'],
  ['000134', 'blugirl'],
  ['000135', 'blumarine'],
  ['000136', 'bmw'],
  ['000137', 'boccadamo'],
  ['000138', 'boccia'],
  ['000139', 'boggi'],
  ['000140', 'bois-1920'],
  ['000141', 'bomb-boogie'],
  ['000142', 'bonprix'],
  ['000143', 'borbonese'],
  ['000144', 'borsalino'],
  ['000145', 'bottega-veneta'],
  ['000146', 'bottega-verde'],
  ['000147', 'boxeur-des-rues'],
  ['000148', 'boy-london'],
  ['000149', 'braccialini'],
  ['000150', 'brandina'],
  ['000151', 'braun'],
  ['000152', 'brazilian-crush'],
  ['000153', 'breil'],
  ['000154', 'breitling'],
  ['000155', 'brema'],
  ['000156', 'brics'],
  ['000157', 'brioni'],
  ['000158', 'broke'],
  ['000159', 'brooks'],
  ['000160', 'brooks-brothers'],
  ['000161', 'brooksfield'],
  ['000162', 'brosway'],
  ['000163', 'brugi'],
  ['000164', 'brums'],
  ['000165', 'brunello-cucinelli'],
  ['000166', 'bruno'],
  ['000167', 'bugatti'],
  ['000168', 'bulova'],
  ['000169', 'burberry'],
  ['000170', 'burton'],
  ['000171', 'bvlgari'],
  ['000172', 'byblos'],
  ['000173', 'c-p-company'],
  ['000174', 'cache-cache'],
  ['000175', 'cady'],
  ['000176', 'cafenoir'],
  ['000177', 'caffe-borbone-limited'],
  ['000178', 'calibrato'],
  ['000179', 'calliope'],
  ['000180', 'calvin-klein'],
  ['000181', 'calzedonia'],
  ['000182', 'calzuro'],
  ['000183', 'camaieu'],
  ['000184', 'camicissima'],
  ['000185', 'camomilla'],
  ['000186', 'campanile'],
  ['000187', 'camper'],
  ['000188', 'campo-marzio'],
  ['000189', 'canada-goose'],
  ['000190', 'canadians'],
  ['000191', 'canali'],
  ['000192', 'candyfl'],
  ['000193', 'cape-horn'],
  ['000194', 'caporiccio'],
  ['000195', 'cappello-point'],
  ['000196', 'car-shoe'],
  ['000197', 'caracciolo'],
  ['000198', 'caractere'],
  ['000199', 'carhartt'],
  ['000200', 'carioca'],
  ['000201', 'carla-ferroni'],
  ['000202', 'carlo-pignatelli'],
  ['000203', 'carlo-raspagni'],
  ['000204', 'carlsberg'],
  ['000205', 'carolina-herrera'],
  ['000206', 'carpisa'],
  ['000207', 'carrera'],
  ['000208', 'carthusia'],
  ['000209', 'cartier'],
  ['000210', 'casa-di-carta'],
  ['000211', 'casablanca'],
  ['000212', 'casadei'],
  ['000213', 'cashmere'],
  ['000214', 'casio'],
  ['000215', 'cavalleria-toscana'],
  ['000216', 'cavalli-class'],
  ['000217', 'celine'],
  ['000218', 'celio'],
  ['000219', 'centro-sposi-paradiso'],
  ['000220', 'cerim'],
  ['000221', 'cerruti'],
  ['000222', 'certina'],
  ['000223', 'cesare-paciotti'],
  ['000224', 'cesaro-atelier'],
  ['000225', 'champion'],
  ['000226', 'chanel'],
  ['000227', 'charms-parish-haute-couture'],
  ['000228', 'charro'],
  ['000229', 'cheap-monday'],
  ['000230', 'chelsea'],
  ['000231', 'chevignon'],
  ['000232', 'chiara-contini'],
  ['000233', 'chiara-ferragni'],
  ['000234', 'chicco'],
  ['000235', 'chimento'],
  ['000236', 'chloe'],
  ['000237', 'hogan'],
  ['000238', 'chopard'],
  ['000239', 'christian-dior'],
  ['000240', 'christian-koehlert'],
  ['000241', 'christian-louboutin'],
  ['000242', 'chronotech'],
  ['000243', 'church-s'],
  ['000244', 'ciesse-piumini'],
  ['000245', 'cinteso-florence'],
  ['000246', 'cinti'],
  ['000247', 'citizen'],
  ['000248', 'claraluna'],
  ['000249', 'clarks'],
  ['000250', 'classified-uk'],
  ['000251', 'clayton'],
  ['000252', 'clessidra'],
  ['000253', 'cmp'],
  ['000254', 'coach'],
  ['000255', 'coca-cola'],
  ['000256', 'coccinelle'],
  ['000257', 'coccodrillo'],
  ['000258', 'coconuda'],
  ['000259', 'cofra'],
  ['000260', 'colmar'],
  ['000261', 'columbia'],
  ['000262', 'combipel'],
  ['000263', 'comete-gioielli'],
  ['000264', 'comix'],
  ['000265', 'comme-des-garçons'],
  ['000266', 'conbipel'],
  ['000267', 'converse'],
  ['000268', 'corneliani'],
  ['000269', 'corteiz'],
  ['000270', 'corum'],
  ['000271', 'cos'],
  ['000272', 'costume-national'],
  ['000273', 'cotton-silk'],
  ['000274', 'cotton-belt'],
  ['000275', 'cotton-club'],
  ['000276', 'creed'],
  ['000277', 'crepaz'],
  ['000278', 'crime-london'],
  ['000279', 'crispi'],
  ['000280', 'cross'],
  ['000281', 'cruciani'],
  ['000282', 'cult'],
  ['000283', 'custo-barcelona'],
  ['000284', 'cycle'],
  ['000285', 'd-a-t-e'],
  ['000286', 'daenerys'],
  ['000287', 'daina'],
  ['000288', 'damiani'],
  ['000289', 'danese'],
  ['000290', 'daniel-wellington'],
  ['000291', 'daniele-alessandrini'],
  ['000292', 'dasko'],
  ['000293', 'datch'],
  ['000294', 'davidoff'],
  ['000295', 'de-fonseca'],
  ['000296', 'decathlon'],
  ['000297', 'deja-vu'],
  ['000298', 'dekker'],
  ['000299', 'deliveroo'],
  ['000300', 'delsey'],
  ['000301', 'delta-plus'],
  ['000302', 'demi-buff'],
  ['000303', 'denny-rose'],
  ['000304', 'desigual'],
  ['000305', 'desj'],
  ['000306', 'diadora'],
  ['000307', 'diana'],
  ['000308', 'dickies'],
  ['000309', 'diesel'],
  ['000310', 'dior'],
  ['000311', 'disclaimer'],
  ['000312', 'disney'],
  ['000313', 'diverse'],
  ['000314', 'dixie'],
  ['000315', 'dkb'],
  ['000316', 'dkny'],
  ['000317', 'dmc'],
  ['000318', 'dockers'],
  ['000319', 'dodo'],
  ['000320', 'dolce-gabbana'],
  ['000321', 'dolce-vita'],
  ['000322', 'dolomite'],
  ['000323', 'dondup'],
  ['000324', 'donjoy'],
  ['000325', 'donnay'],
  ['000326', 'dooa'],
  ['000327', 'doxa'],
  ['000328', 'dr-gibaud'],
  ['000329', 'dr-martens'],
  ['000330', 'dr-scholl-s'],
  ['000331', 'dries-van-noten'],
  ['000332', 'dsquared'],
  ['000333', 'dsquared2'],
  ['000334', 'ducati'],
  ['000335', 'dynafit'],
  ['000336', 'dyson'],
  ['000337', 'e-marinella'],
  ['000338', 'ea7-emporio-armani'],
  ['000339', 'eastpak'],
  ['000340', 'ebel'],
  ['000341', 'eberhard'],
  ['000342', 'ecko'],
  ['000343', 'ecosanit'],
  ['000344', 'edas'],
  ['000345', 'eddy-monetti'],
  ['000346', 'egosan'],
  ['000347', 'eight-bob'],
  ['000348', 'el-charro'],
  ['000349', 'element'],
  ['000350', 'elena-miro'],
  ['000351', 'elisabetta-franchi'],
  ['000352', 'ellesse'],
  ['000353', 'emilio-pucci'],
  ['000354', 'emp'],
  ['000355', 'emporio-armani'],
  ['000356', 'energie'],
  ['000357', 'energy'],
  ['000358', 'enicar'],
  ['000359', 'enrico-coveri'],
  ['000360', 'enrico-maria'],
  ['000361', 'eredi-pisano'],
  ['000362', 'ermanno-scervino'],
  ['000363', 'ermenegildo-zegna'],
  ['000364', 'errea'],
  ['000365', 'escentric-molecules'],
  ['000366', 'esercito-italiano'],
  ['000367', 'esprit'],
  ['000368', 'essence-of-australia'],
  ['000369', 'etat-libre-d-orange'],
  ['000370', 'eternal'],
  ['000371', 'etro'],
  ['000372', 'everlast'],
  ['000373', 'evoe'],
  ['000374', 'f-k'],
  ['000375', 'fabi'],
  ['000376', 'fabiana-ferry'],
  ['000377', 'facchini'],
  ['000378', 'facis'],
  ['000379', 'falconeri'],
  ['000380', 'fay'],
  ['000381', 'fear-of-god'],
  ['000382', 'fendi'],
  ['000383', 'fenix'],
  ['000384', 'ferragamo'],
  ['000385', 'ferre'],
  ['000386', 'ferrero'],
  ['000387', 'festina'],
  ['000388', 'fila'],
  ['000389', 'fiorella-rubino'],
  ['000390', 'fiorentina'],
  ['000391', 'fiorucci'],
  ['000392', 'fitbit'],
  ['000393', 'five-ten'],
  ['000394', 'fizik'],
  ['000395', 'fjallraven'],
  ['000396', 'flavio-castellani'],
  ['000397', 'florsheim'],
  ['000398', 'fly-flot'],
  ['000399', 'foot-korner'],
  ['000400', 'foreo'],
  ['000401', 'forever-21'],
  ['000402', 'forma'],
  ['000403', 'fornarina'],
  ['000404', 'fossil'],
  ['000405', 'fox-racing'],
  ['000406', 'fracomina'],
  ['000407', 'fradi'],
  ['000408', 'francesca-bianco'],
  ['000409', 'francesco-biasia'],
  ['000410', 'frankie-morello'],
  ['000411', 'fratelli-rossetti'],
  ['000412', 'frau'],
  ['000413', 'fray'],
  ['000414', 'frecce-tricolori'],
  ['000415', 'fred-mello'],
  ['000416', 'fred-perry'],
  ['000417', 'freddy'],
  ['000418', 'frederique-constant'],
  ['000419', 'freitag'],
  ['000420', 'fruit-of-the-loom'],
  ['000421', 'furla'],
  ['000422', 'fury'],
  ['000423', 'fuso'],
  ['000424', 'g-shock'],
  ['000425', 'g-star'],
  ['000426', 'gabriella-chieffo'],
  ['000427', 'gabs'],
  ['000428', 'gaelle-paris'],
  ['000429', 'gaerne'],
  ['000430', 'gallo'],
  ['000431', 'gant'],
  ['000432', 'gap'],
  ['000433', 'garmin'],
  ['000434', 'gas'],
  ['000435', 'gattinoni'],
  ['000436', 'gaudi'],
  ['000437', 'gcds'],
  ['000438', 'gel-for-nail'],
  ['000439', 'generic'],
  ['000440', 'genny'],
  ['000441', 'genutrain'],
  ['000442', 'geospirit'],
  ['000443', 'geox'],
  ['000444', 'gesco'],
  ['000445', 'gessato'],
  ['000446', 'ghd'],
  ['000447', 'gherardini'],
  ['000448', 'giancarlo-paoli'],
  ['000449', 'gianfranco-ferre'],
  ['000450', 'gianmarco-venturi'],
  ['000451', 'gianni-chiarini'],
  ['000452', 'gianni-versace'],
  ['000453', 'giardini-di-toscana'],
  ['000454', 'gibaud'],
  ['000455', 'giesswein'],
  ['000456', 'gilda'],
  ['000457', 'gillette'],
  ['000458', 'giocellini'],
  ['000459', 'giorgio-armani'],
  ['000460', 'giotto'],
  ['000461', 'giovanna-sbiroli'],
  ['000462', 'giovanni-raspini'],
  ['000463', 'gipsy'],
  ['000464', 'girard-perregaux'],
  ['000465', 'giuseppe-zanotti'],
  ['000466', 'givenchy'],
  ['000467', 'givova'],
  ['000468', 'globetrotter'],
  ['000469', 'glycine'],
  ['000470', 'golden-goose'],
  ['000471', 'gore-tex'],
  ['000472', 'gormiti'],
  ['000473', 'goyard'],
  ['000474', 'graceland'],
  ['000475', 'grifoni'],
  ['000476', 'grisport'],
  ['000477', 'grunland'],
  ['000478', 'gucci'],
  ['000479', 'guerbois'],
  ['000480', 'guerlain'],
  ['000481', 'guess'],
  ['000482', 'guru'],
  ['000483', 'h-m'],
  ['000484', 'habana'],
  ['000485', 'hamilton'],
  ['000486', 'hand-made'],
  ['000487', 'hanny-deep'],
  ['000488', 'happy-hosse'],
  ['000489', 'hard-rock-cafe'],
  ['000490', 'harley-davidson'],
  ['000491', 'harmont-blaine'],
  ['000492', 'harry-potter'],
  ['000493', 'havaianas'],
  ['000494', 'hbarc'],
  ['000495', 'head'],
  ['000496', 'heavy-project'],
  ['000497', 'heelys'],
  ['000498', 'hello-kitty'],
  ['000499', 'helly-hansen'],
  ['000500', 'helvetia'],
  ['000501', 'henri-lloyd'],
  ['000502', 'henry-cotton-s'],
  ['000503', 'hermes'],
  ['000504', 'herno'],
  ['000505', 'heron-preston'],
  ['000506', 'hip-hop'],
  ['000507', 'hipanema'],
  ['000508', 'hoka-one-one'],
  ['000509', 'hollister'],
  ['000510', 'homar-piragino'],
  ['000511', 'honey'],
  ['000512', 'honeywell'],
  ['000513', 'hoodrich'],
  ['000514', 'hoops'],
  ['000515', 'htc-company'],
  ['000516', 'huawei'],
  ['000517', 'hublot'],
  ['000518', 'hue'],
  ['000519', 'huf'],
  ['000520', 'hugo-boss'],
  ['000521', 'hunter'],
  ['000522', 'i-gabbiani'],
  ['000523', 'i-clip'],
  ['000524', 'iba-racing'],
  ['000525', 'iceberg'],
  ['000526', 'icepeak'],
  ['000527', 'igi-co'],
  ['000528', 'il-laccio'],
  ['000529', 'imetec'],
  ['000530', 'imperial'],
  ['000531', 'inblu'],
  ['000532', 'inda'],
  ['000533', 'infiore'],
  ['000534', 'ingram'],
  ['000535', 'inter'],
  ['000536', 'intimissimi'],
  ['000537', 'intrend'],
  ['000538', 'invicta'],
  ['000539', 'italia-independent'],
  ['000540', 'italian-army'],
  ['000541', 'italian-format'],
  ['000542', 'iwc-schaffhausen'],
  ['000543', 'ixon'],
  ['000544', 'ixos'],
  ['000545', 'jack-jones'],
  ['000546', 'jacob-cohen'],
  ['000547', 'jacquemus'],
  ['000548', 'jadea'],
  ['000549', 'jaeger-lecoultre'],
  ['000550', 'jaguar'],
  ['000551', 'jean-paul-gaultier'],
  ['000552', 'jeckerson'],
  ['000553', 'jeffrey-campbell'],
  ['000554', 'jijil'],
  ['000555', 'jil-sander'],
  ['000556', 'jimmy-choo'],
  ['000557', 'jobst'],
  ['000558', 'john-ashfield'],
  ['000559', 'john-richmond'],
  ['000560', 'joma'],
  ['000561', 'joseph-ribkoff'],
  ['000562', 'jsp'],
  ['000563', 'just-cavalli'],
  ['000564', 'juventus'],
  ['000565', 'k-swiss'],
  ['000566', 'k-way'],
  ['000567', 'kador'],
  ['000568', 'kalenji'],
  ['000569', 'kappa'],
  ['000570', 'karl-lagerfeld'],
  ['000571', 'karpos'],
  ['000572', 'kejo'],
  ['000573', 'kenzo'],
  ['000574', 'kiabi'],
  ['000575', 'kidult'],
  ['000576', 'kienzle'],
  ['000577', 'kiko-milano'],
  ['000578', 'kipling'],
  ['000579', 'kipsta'],
  ['000580', 'kiton'],
  ['000581', 'kocca'],
  ['000582', 'krizia'],
  ['000583', 'ktm'],
  ['000584', 'l-erbolario'],
  ['000585', 'l-oreal'],
  ['000586', 'la-castellana'],
  ['000587', 'la-martina'],
  ['000588', 'la-murrina'],
  ['000589', 'la-perla'],
  ['000590', 'la-ribelle'],
  ['000591', 'la-sportiva'],
  ['000592', 'lacoste'],
  ['000593', 'lamborghini'],
  ['000594', 'lancaster'],
  ['000595', 'lancetti'],
  ['000596', 'lanco'],
  ['000597', 'lancome'],
  ['000598', 'landeron'],
  ['000599', 'lanvin'],
  ['000600', 'lapin'],
  ['000601', 'lastminute'],
  ['000602', 'lattafa'],
  ['000603', 'laura-biagiotti'],
  ['000604', 'laurens'],
  ['000605', 'lavand'],
  ['000606', 'lazzari'],
  ['000607', 'le-coq-sportif'],
  ['000608', 'le-frag'],
  ['000609', 'le-pandorine'],
  ['000610', 'le-preziose-di-chiara'],
  ['000611', 'leble'],
  ['000612', 'lee'],
  ['000613', 'leg-avenue'],
  ['000614', 'legea'],
  ['000615', 'legnago'],
  ['000616', 'les-copains'],
  ['000617', 'levante'],
  ['000618', 'levi-strauss'],
  ['000619', 'levi-s'],
  ['000620', 'li-ning'],
  ['000621', 'lidl'],
  ['000622', 'liu-jo'],
  ['000623', 'lizard'],
  ['000624', 'locman'],
  ['000625', 'loden'],
  ['000626', 'longchamp'],
  ['000627', 'longines'],
  ['000628', 'lonsdale'],
  ['000629', 'loren'],
  ['000630', 'loro-piana'],
  ['000631', 'lorus'],
  ['000632', 'lotto'],
  ['000633', 'lotus'],
  ['000634', 'louis-vuitton'],
  ['000635', 'lovable'],
  ['000636', 'love-moschino'],
  ['000637', 'lover-s'],
  ['000638', 'lozza'],
  ['000639', 'lubiam'],
  ['000640', 'luciano-barachini'],
  ['000641', 'luisa-spagnoli'],
  ['000642', 'lumberjack'],
  ['000643', 'luminox'],
  ['000644', 'lyle-scott'],
  ['000645', 'macron'],
  ['000646', 'made-in-italy'],
  ['000647', 'maggie-sottero'],
  ['000648', 'maison-margiela'],
  ['000649', 'maliparmi'],
  ['000650', 'mammut'],
  ['000651', 'manchester-city'],
  ['000652', 'manchester-united'],
  ['000653', 'mandarina-duck'],
  ['000654', 'mangano'],
  ['000655', 'mango'],
  ['000656', 'mani'],
  ['000657', 'manila-grace'],
  ['000658', 'manuel-ritz'],
  ['000659', 'marc-by-marc-jacobs'],
  ['000660', 'marc-jacobs'],
  ['000661', 'marcelo-burlon'],
  ['000662', 'marella'],
  ['000663', 'maria-vittoria-vinci'],
  ['000664', 'mariella-burani'],
  ['000665', 'marina-militare'],
  ['000666', 'marina-rinaldi'],
  ['000667', 'marina-yachting'],
  ['000668', 'mario-valentino'],
  ['000669', 'marisa-padovan'],
  ['000670', 'marks-spencer'],
  ['000671', 'markup'],
  ['000672', 'marlboro-classics'],
  ['000673', 'marlu'],
  ['000674', 'marmot'],
  ['000675', 'martin-line'],
  ['000676', 'martone'],
  ['000677', 'marvel'],
  ['000678', 'marvin'],
  ['000679', 'maserati'],
  ['000680', 'massimo-dutti'],
  ['000681', 'massimo-rebecchi'],
  ['000682', 'maui-and-sons'],
  ['000683', 'maurice-lacroix'],
  ['000684', 'mauro-grifoni'],
  ['000685', 'mauro-leone'],
  ['000686', 'max-co'],
  ['000687', 'max-mara'],
  ['000688', 'maxter'],
  ['000689', 'mayoral'],
  ['000690', 'mbt'],
  ['000691', 'mc2-saint-barth'],
  ['000692', 'mckinley'],
  ['000693', 'meindl'],
  ['000694', 'melissa'],
  ['000695', 'melluso'],
  ['000696', 'mercedes-benz'],
  ['000697', 'merrell'],
  ['000698', 'messi'],
  ['000699', 'met'],
  ['000700', 'mgpsportswear'],
  ['000701', 'mia-bag'],
  ['000702', 'michael-kors'],
  ['000703', 'michael-phelps'],
  ['000704', 'microretina-lycra'],
  ['000705', 'milanoo'],
  ['000706', 'miluna'],
  ['000707', 'mimosa'],
  ['000708', 'minimil'],
  ['000709', 'mink'],
  ['000710', 'miss-miss'],
  ['000711', 'miss-sixty'],
  ['000712', 'missoni'],
  ['000713', 'mistral'],
  ['000714', 'mitech'],
  ['000715', 'miu-miu'],
  ['000716', 'mizuno'],
  ['000717', 'moleskine'],
  ['000718', 'molly-bracken'],
  ['000719', 'momo-design'],
  ['000720', 'moncler'],
  ['000721', 'monnalisa'],
  ['000722', 'monsignore'],
  ['000723', 'montale'],
  ['000724', 'montblanc'],
  ['000725', 'montgomery'],
  ['000726', 'montura'],
  ['000727', 'monviso'],
  ['000728', 'moon-boot'],
  ['000729', 'moorer'],
  ['000730', 'morato'],
  ['000731', 'morellato'],
  ['000732', 'moreschi'],
  ['000733', 'morilee'],
  ['000734', 'moschino'],
  ['000735', 'moscot'],
  ['000736', 'motivi'],
  ['000737', 'mou'],
  ['000738', 'movado'],
  ['000739', 'msgm'],
  ['000740', 'munich'],
  ['000741', 'murano'],
  ['000742', 'murphy-nye'],
  ['000743', 'musani'],
  ['000744', 'museum'],
  ['000745', 'na-kd'],
  ['000746', 'nabeel'],
  ['000747', 'naf-naf'],
  ['000748', 'naj-oleari'],
  ['000749', 'nannini'],
  ['000750', 'napapijri'],
  ['000751', 'nara'],
  ['000752', 'narciso-rodriguez'],
  ['000753', 'nasomatto'],
  ['000754', 'nata-camicie'],
  ['000755', 'naturana'],
  ['000756', 'nature-s'],
  ['000757', 'nautica'],
  ['000758', 'nava'],
  ['000759', 'navigare'],
  ['000760', 'nazar-khitsyak'],
  ['000761', 'nazareno-gabrielli'],
  ['000762', 'nba'],
  ['000763', 'neilpryde'],
  ['000764', 'nero-giardini'],
  ['000765', 'nerogiardini'],
  ['000766', 'new-balance'],
  ['000767', 'new-era'],
  ['000768', 'niclo'],
  ['000769', 'nicole-milano'],
  ['000770', 'nicolet'],
  ['000771', 'nike'],
  ['000772', 'niki-nika'],
  ['000773', 'nixon'],
  ['000774', 'no-name'],
  ['000775', 'noi'],
  ['000776', 'nolita'],
  ['000777', 'nologo'],
  ['000778', 'nomination'],
  ['000779', 'non-clothing'],
  ['000780', 'none'],
  ['000781', 'nordica'],
  ['000782', 'north-sails'],
  ['000783', 'northwave'],
  ['000784', 'novecento'],
  ['000785', 'nuna-lie'],
  ['000786', 'nuoro'],
  ['000787', 'nuvolari'],
  ['000788', 'nymos'],
  ['000789', 'o-bag'],
  ['000790', 'o-neill'],
  ['000791', 'oakley'],
  ['000792', 'ob'],
  ['000793', 'obey'],
  ['000794', 'obsessive'],
  ['000795', 'octopus'],
  ['000796', 'off-white'],
  ['000797', 'oggettistica-in-resina'],
  ['000798', 'olang'],
  ['000799', 'oliver-peoples'],
  ['000800', 'olivia-hop'],
  ['000801', 'oltre'],
  ['000802', 'omega'],
  ['000803', 'omp'],
  ['000804', 'onepiece'],
  ['000805', 'onitsuka-tiger'],
  ['000806', 'only'],
  ['000807', 'ops-objects'],
  ['000808', 'orciani'],
  ['000809', 'orient'],
  ['000810', 'original-marines'],
  ['000811', 'oris'],
  ['000812', 'orthoservice'],
  ['000813', 'orto-parisi'],
  ['000814', 'ovs'],
  ['000815', 'oysho'],
  ['000816', 'p-a-r-o-s-h'],
  ['000817', 'paco-rabanne'],
  ['000818', 'pagani'],
  ['000819', 'pagani-design'],
  ['000820', 'pail'],
  ['000821', 'pal-zileri'],
  ['000822', 'palm-angels'],
  ['000823', 'panciotti'],
  ['000824', 'pandora'],
  ['000825', 'panerai'],
  ['000826', 'paola-frani'],
  ['000827', 'paolo-casalini'],
  ['000828', 'paprika'],
  ['000829', 'parah'],
  ['000830', 'parkside'],
  ['000831', 'particolare'],
  ['000832', 'pastorelli'],
  ['000833', 'patagonia'],
  ['000834', 'patek-philippe'],
  ['000835', 'patrizia-pepe'],
  ['000836', 'paul-shark'],
  ['000837', 'paul-picot'],
  ['000838', 'paul-smith'],
  ['000839', 'pecora'],
  ['000840', 'pennyblack'],
  ['000841', 'people-of-shibuya'],
  ['000842', 'pepe-jeans'],
  ['000843', 'percento'],
  ['000844', 'perseo'],
  ['000845', 'persol'],
  ['000846', 'petit'],
  ['000847', 'petit-bateau'],
  ['000848', 'peuterey'],
  ['000849', 'phard'],
  ['000850', 'philip-watch'],
  ['000851', 'philipp-plein'],
  ['000852', 'philippe-matignon'],
  ['000853', 'piaget'],
  ['000854', 'piazza-italia'],
  ['000855', 'pieralisi'],
  ['000856', 'piero-guidi'],
  ['000857', 'piero-moretti'],
  ['000858', 'pierre-cardin'],
  ['000859', 'pimkie'],
  ['000860', 'pineider'],
  ['000861', 'pinko'],
  ['000862', 'piquadro'],
  ['000863', 'pirelli'],
  ['000864', 'pissei'],
  ['000865', 'pittarello'],
  ['000866', 'playlife'],
  ['000867', 'playtex'],
  ['000868', 'please'],
  ['000869', 'polarized'],
  ['000870', 'polaroid'],
  ['000871', 'police'],
  ['000872', 'poljot'],
  ['000873', 'pollini'],
  ['000874', 'pomellato'],
  ['000875', 'pompea'],
  ['000876', 'porsche'],
  ['000877', 'portachiavi'],
  ['000878', 'positano'],
  ['000879', 'power'],
  ['000880', 'powerflower'],
  ['000881', 'prada'],
  ['000882', 'premiata'],
  ['000883', 'prenatal'],
  ['000884', 'prestigiosa'],
  ['000885', 'prima-classe'],
  ['000886', 'primadonna'],
  ['000887', 'primark'],
  ['000888', 'primigi'],
  ['000889', 'primo-emporio'],
  ['000890', 'profi'],
  ['000891', 'promod'],
  ['000892', 'pronovias'],
  ['000893', 'propaganda'],
  ['000894', 'prostep'],
  ['000895', 'pryngeps'],
  ['000896', 'psg'],
  ['000897', 'pull-bear'],
  ['000898', 'pulsar'],
  ['000899', 'puma'],
  ['000900', 'pyrex'],
  ['000901', 'quechua'],
  ['000902', 'queen-isabel'],
  ['000903', 'quiksilver'],
  ['000904', 'qwertee'],
  ['000905', 'radiosa'],
  ['000906', 'rado'],
  ['000907', 'rains'],
  ['000908', 'raketa'],
  ['000909', 'ralph-lauren'],
  ['000910', 'raspini'],
  ['000911', 'rasta'],
  ['000912', 'ray-ban'],
  ['000913', 'raymond-weil'],
  ['000914', 'rb'],
  ['000915', 're-hash'],
  ['000916', 'rea'],
  ['000917', 'real-madrid'],
  ['000918', 'rebecca'],
  ['000919', 'red-bull'],
  ['000920', 'reda'],
  ['000921', 'reebok'],
  ['000922', 'reef'],
  ['000923', 'refrigiwear'],
  ['000924', 'refrigue'],
  ['000925', 'renato-balestra'],
  ['000926', 'replay'],
  ['000927', 'reporter'],
  ['000928', 'retrosuperfuture'],
  ['000929', 'rev-it'],
  ['000930', 'reverse'],
  ['000931', 'revlon'],
  ['000932', 'revue'],
  ['000933', 'rifle'],
  ['000934', 'rinascimento'],
  ['000935', 'rituals'],
  ['000936', 'roamer'],
  ['000937', 'robe-di-kappa'],
  ['000938', 'roberta-di-camerino'],
  ['000939', 'roberto-cavalli'],
  ['000940', 'roccobarocco'],
  ['000941', 'rodriguez'],
  ['000942', 'roland-garros'],
  ['000943', 'rolex'],
  ['000944', 'roma'],
  ['000945', 'romano'],
  ['000946', 'romano-botta'],
  ['000947', 'romeo-gigli'],
  ['000948', 'roncato'],
  ['000949', 'ronson'],
  ['000950', 'ronton'],
  ['000951', 'rossignol'],
  ['000952', 'rosso-di-sera'],
  ['000953', 'rowenta'],
  ['000954', 'roy-roger-s'],
  ['000955', 'rrd'],
  ['000956', 's-t-dupont'],
  ['000957', 's-agapo'],
  ['000958', 'saint-barth'],
  ['000959', 'saint-laurent'],
  ['000960', 'salewa'],
  ['000961', 'salomon'],
  ['000962', 'salvatore-ferragamo'],
  ['000963', 'salvini'],
  ['000964', 'samsonite'],
  ['000965', 'samsung'],
  ['000966', 'san-patrick'],
  ['000967', 'sandro-ferrone'],
  ['000968', 'santoni'],
  ['000969', 'sash'],
  ['000970', 'sassy-witch'],
  ['000971', 'saucony'],
  ['000972', 'save-my-bag'],
  ['000973', 'save-the-duck'],
  ['000974', 'scholl'],
  ['000975', 'scorpion-bay'],
  ['000976', 'scott'],
  ['000977', 'scout'],
  ['000978', 'scuderi'],
  ['000979', 'seamless'],
  ['000980', 'sebago'],
  ['000981', 'sector'],
  ['000982', 'segue'],
  ['000983', 'seiko'],
  ['000984', 'selene'],
  ['000985', 'sendra'],
  ['000986', 'sensalou'],
  ['000987', 'senza-marca'],
  ['000988', 'serafini'],
  ['000989', 'serenity'],
  ['000990', 'sergio-rossi'],
  ['000991', 'sergio-tacchini'],
  ['000992', 'seven'],
  ['000993', 'seven-til-midnight'],
  ['000994', 'seventy'],
  ['000995', 'shein'],
  ['000996', 'shirt-passion'],
  ['000997', 'shop-art'],
  ['000998', 'sicura'],
  ['000999', 'sidi'],
  ['001000', 'siggi'],
  ['001001', 'sigvaris'],
  ['001002', 'silhouette'],
  ['001003', 'silvian-heach'],
  ['001004', 'simone-perele'],
  ['001005', 'sisi'],
  ['001006', 'sisley'],
  ['001007', 'siviglia'],
  ['001008', 'six2'],
  ['001009', 'skechers'],
  ['001010', 'slam'],
  ['001011', 'sloggi'],
  ['001012', 'sodini'],
  ['001013', 'solo-soprani'],
  ['001014', 'sonny-bono'],
  ['001015', 'sorbino'],
  ['001016', 'sorbothane'],
  ['001017', 'sorel'],
  ['001018', 'sospiro'],
  ['001019', 'spalding'],
  ['001020', 'sparco'],
  ['001021', 'specchio'],
  ['001022', 'spidi'],
  ['001023', 'spieth-wensky'],
  ['001024', 'spinomed'],
  ['001025', 'sposissimi'],
  ['001026', 'sprayground'],
  ['001027', 'spyder'],
  ['001028', 'spyke'],
  ['001029', 'squale'],
  ['001030', 'squid-game'],
  ['001031', 'ssc-napoli'],
  ['001032', 'star-wars'],
  ['001033', 'stefanel'],
  ['001034', 'steinhart'],
  ['001035', 'stella-mccartney'],
  ['001036', 'steve-madden'],
  ['001037', 'stone-island'],
  ['001038', 'stonefly'],
  ['001039', 'stradivarius'],
  ['001040', 'strega'],
  ['001041', 'stroili-oro'],
  ['001042', 'stussy'],
  ['001043', 'subdued'],
  ['001044', 'sulli'],
  ['001045', 'sun-68'],
  ['001046', 'sundek'],
  ['001047', 'suomy'],
  ['001048', 'superdry'],
  ['001049', 'superga'],
  ['001050', 'supreme'],
  ['001051', 'susy-mix'],
  ['001052', 'suunto'],
  ['001053', 'swarovski'],
  ['001054', 'swatch'],
  ['001055', 'swear-years'],
  ['001056', 'tabasco'],
  ['001057', 'tag-heuer'],
  ['001058', 'tagliatore'],
  ['001059', 'tait'],
  ['001060', 'talco'],
  ['001061', 'tallia-di-delfino'],
  ['001062', 'tally-weijl'],
  ['001063', 'tata'],
  ['001064', 'tcx'],
  ['001065', 'tecnica'],
  ['001066', 'teletubbies'],
  ['001067', 'tena'],
  ['001068', 'terranova'],
  ['001069', 'tezenis'],
  ['001070', 'the-bridge'],
  ['001071', 'the-north-face'],
  ['001072', 'the-seller'],
  ['001073', 'thierry-mugler'],
  ['001074', 'think-pink'],
  ['001075', 'thrasher'],
  ['001076', 'thun'],
  ['001077', 'tiffany-co'],
  ['001078', 'tiger'],
  ['001079', 'tim-camino'],
  ['001080', 'timberland'],
  ['001081', 'timex'],
  ['001082', 'tissot'],
  ['001083', 'tnt'],
  ['001084', 'tod-s'],
  ['001085', 'toh'],
  ['001086', 'tolv'],
  ['001087', 'tom-ford'],
  ['001088', 'tommy-hilfiger'],
  ['001089', 'tommy-jeans'],
  ['001090', 'top-gun'],
  ['001091', 'torino'],
  ['001092', 'tosca-blu'],
  ['001093', 'trachten'],
  ['001094', 'trapstar'],
  ['001095', 'traverse'],
  ['001096', 'travis-scott'],
  ['001097', 'triumph'],
  ['001098', 'trollbeads'],
  ['001099', 'trussardi'],
  ['001100', 'tuc-tuc'],
  ['001101', 'tucano'],
  ['001102', 'tucano-urbano'],
  ['001103', 'tudor'],
  ['001104', 'turche'],
  ['001105', 'tuum'],
  ['001106', 'twinset'],
  ['001107', 'tyr'],
  ['001108', 'u-s-polo-assn'],
  ['001109', 'ufo'],
  ['001110', 'ugg'],
  ['001111', 'ultraflirt'],
  ['001112', 'umbro'],
  ['001113', 'under-armour'],
  ['001114', 'undiz'],
  ['001115', 'uniqlo'],
  ['001116', 'united-colors-of-benetton'],
  ['001117', 'universal-geneve'],
  ['001118', 'unoaerre'],
  ['001119', 'upim'],
  ['001120', 'v-73'],
  ['001121', 'vacheron-constantin'],
  ['001122', 'vagary'],
  ['001123', 'valentino'],
  ['001124', 'valleverde'],
  ['001125', 'van-cleef-arpels'],
  ['001126', 'vanderbilt'],
  ['001127', 'vans'],
  ['001128', 'vanson'],
  ['001129', 'varie'],
  ['001130', 'vdp'],
  ['001131', 'vega'],
  ['001132', 'vegecam'],
  ['001133', 'veglia'],
  ['001134', 'veneziani'],
  ['001135', 'venus'],
  ['001136', 'vera-wang'],
  ['001137', 'vero-moda'],
  ['001138', 'versace'],
  ['001139', 'vespa'],
  ['001140', 'vetta'],
  ['001141', 'via-delle-perle'],
  ['001142', 'via-roma-15'],
  ['001143', 'vibram'],
  ['001144', 'vic-matie'],
  ['001145', 'vicolo'],
  ['001146', 'victoria'],
  ['001147', 'victoria-s-secret'],
  ['001148', 'viktor-rolf'],
  ['001149', 'vintage'],
  ['001150', 'vision'],
  ['001151', 'vivienne'],
  ['001152', 'vivienne-westwood'],
  ['001153', 'vivis'],
  ['001154', 'vlone'],
  ['001155', 'vogue'],
  ['001156', 'volcom'],
  ['001157', 'vostok'],
  ['001158', 'wahl'],
  ['001159', 'wamcraft'],
  ['001160', 'wampum'],
  ['001161', 'web'],
  ['001162', 'wed-ze'],
  ['001163', 'west-scout'],
  ['001164', 'windsor-smith'],
  ['001165', 'wolford'],
  ['001166', 'woolrich'],
  ['001167', 'wow-bra'],
  ['001168', 'wrangler'],
  ['001169', 'wyler'],
  ['001170', 'xacus'],
  ['001171', 'xcape'],
  ['001172', 'xiaomi'],
  ['001173', 'xti'],
  ['001174', 'yamaha'],
  ['001175', 'yamamay'],
  ['001176', 'yanoir'],
  ['001177', 'yeezy'],
  ['001178', 'yema'],
  ['001179', 'yes-zee'],
  ['001180', 'ynot'],
  ['001181', 'ysl-beauty'],
  ['001182', 'yves-rocher'],
  ['001183', 'yves-saint-laurent'],
  ['001184', 'zanellato'],
  ['001185', 'zara'],
  ['001186', 'zegna'],
  ['001187', 'zelos'],
  ['001188', 'zenith'],
  ['001189', 'zenitsu'],
  ['001190', 'zippo'],
  ['001191', 'zuiki'],
  ['001192', 'zunica'],
]);
