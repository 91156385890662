import type { AdUnitSize } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';
import {
  reloadAdvWithDefaultUnits,
  runAdv,
} from '@client/components/Adv/GPT/sdkIntegration';
import type { CategoryId } from '@sbt-web/networking';
import type { ENV } from '@sbt-web/utils';
import type { DecoratedItem } from '@shared/models';
import React, { useContext } from 'react';
import type { ListingPageProps } from '../../../../pages/listing';
import { AdvContext } from '@client/components/Adv/AdvContext';

export let loadedForTheFirstTime = false;

/**
 * Implemented only to test the hooks
 */
export const resetLoadedForTheFirstTime = function (val: boolean = false) {
  loadedForTheFirstTime = val;
};

export const useAdvListing = function (
  props: ListingPageProps,
  clientReady: boolean,
  isInterstitialDesktopEnabled: boolean,
  skyscraperSizes?: Array<AdUnitSize>
) {
  const [dataLayerReady, setDataLayerReady] = React.useState(false);

  React.useEffect(() => {
    if (
      dataLayerReady &&
      !loadedForTheFirstTime &&
      clientReady === true &&
      skyscraperSizes
    ) {
      loadedForTheFirstTime = true;
      //Set ADV:
      runAdv(
        'listing',
        props.category?.id as CategoryId,
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
        undefined,
        undefined,
        isInterstitialDesktopEnabled,
        skyscraperSizes
      );
    }
  }, [
    dataLayerReady,
    clientReady,
    isInterstitialDesktopEnabled,
    skyscraperSizes,
  ]);

  return {
    setDataLayerReadyForAdv: setDataLayerReady,
  };
};

export const useUpdateAdvWhenItemsChanged = function (
  listingCategoryId: CategoryId,
  items: Array<DecoratedItem>,
  isDesktop: boolean,
  isModalOpened: boolean
) {
  const { skyscraperData } = useContext(AdvContext);

  React.useEffect(() => {
    if (loadedForTheFirstTime && !isModalOpened) {
      reloadAdvWithDefaultUnits(
        isDesktop,
        'listing',
        listingCategoryId,
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
        undefined,
        undefined,
        undefined,
        skyscraperData.sizes
      );
    }
  }, [items, isModalOpened, skyscraperData.sizes]);
};
