import React, { type PropsWithChildren } from 'react';
import { DesktopAdDetailGAMIds } from '@client/components/Adv/GPT';
import {
  AdvDetailContext,
  type AdvDetailContextType,
} from '@client/components/Adv/AdvAdDetailContext';
import { SkeletonWithAdv } from '@client/components/Adv/SkeletonWithAdv';

const SkeletonWithAdvForAdView = ({
  children,
  contextData,
}: { contextData: AdvDetailContextType } & PropsWithChildren) => {
  return (
    <AdvDetailContext.Provider value={contextData}>
      <SkeletonWithAdv
        skyscraperLeft={{
          id: DesktopAdDetailGAMIds.LEFT_SKYSCRAPER,
          enabled: true,
        }}
        skyscraperRight={{
          id: DesktopAdDetailGAMIds.RIGHT_SKYSCRAPER,
          enabled: true,
        }}
        billboard={{ id: DesktopAdDetailGAMIds.BILLBOARD, enabled: true }}
      >
        {children}
      </SkeletonWithAdv>
    </AdvDetailContext.Provider>
  );
};

export { SkeletonWithAdvForAdView, SkeletonWithAdv };
