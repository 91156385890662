import { Headline6 } from '@sbt-web/ui';
import * as React from 'react';
import classes from './ErrorLayout.module.scss';

export interface ELProps {
  title: string;
  message: React.ReactNode;
  image: React.ReactNode;
}

export class ErrorLayout extends React.Component<ELProps> {
  render() {
    const { title, message, image } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.image}>{image}</div>
        <Headline6 classes={[classes.title]}>{title}</Headline6>
        <div className={classes.message}>{message}</div>
      </div>
    );
  }
}
