import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-3',
  Jobs = 'desktop-middle-lp-jobs-3',
  Motor = 'desktop-middle-lp-motor-3',
  Market = 'desktop-middle-lp-market-3',
  RealEstate = 'desktop-middle-lp-realestate-3',
}

const desktopMiddle3Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_TUTTI,
    channel: 'DESKTOP-MIDDLE-LP-TUTTI-3-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle3Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_JOBS,
    channel: 'DESKTOP-MIDDLE-LP-JOBS-3-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle3Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MOTORS,
    channel: 'DESKTOP-MIDDLE-LP-MOTORS-3-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle3Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MARKET,
    channel: 'DESKTOP-MIDDLE-LP-MARKET-3-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle3RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_REALESTATE,
    channel: 'DESKTOP-MIDDLE-LP-REALESTATE-3-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle3: Array<ShortConfiguration> = [
  desktopMiddle3Default,
  desktopMiddle3Job,
  desktopMiddle3Motor,
  desktopMiddle3Market,
  desktopMiddle3RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopMiddle3, variations as DesktopMiddle3 };
