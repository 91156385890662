import type { SubitoAction } from '@reducers/common';
import type { Reducer } from 'redux';

const defaultState: SystemState = {
  preventPopstate: false,
};

export const system: Reducer<SystemState, SystemActions> = (
  state = defaultState,
  action
): SystemState => {
  switch (action.type) {
    case Types.UPDATE_PREVENT_POPSTATE:
      return {
        ...state,
        preventPopstate: action.payload,
      };
    default:
      return state;
  }
};

enum Types {
  UPDATE_PREVENT_POPSTATE = 'set prevent popstate status',
}

type SystemActions = ReturnType<typeof setPreventPopstate>;

/**
 * If set to true, the ListingShallowRouter will stop
 * trying to reconciliate the redux state with the current URL
 */
export function setPreventPopstate(
  active: boolean
): SubitoAction<Types.UPDATE_PREVENT_POPSTATE, boolean> {
  return {
    type: Types.UPDATE_PREVENT_POPSTATE,
    payload: active,
  };
}

export interface SystemState {
  preventPopstate: boolean;
}
