interface BaseFilter {
  uri: string;
  label: string;
}

interface FilterMetadata {
  is_new?: boolean;
}

interface ListFilter extends BaseFilter {
  type: 'list';
  queryString: string;
  dataSource: string;
  multiSelection?: boolean;
  metadata?: FilterMetadata;
}

interface BoolFilter extends BaseFilter {
  type: 'bool';
  queryString: string;
  sublabel?: string;
  metadata?: FilterMetadata;
}

interface RangeFilter extends BaseFilter {
  type: 'range';
  min: ListFilter;
  max: ListFilter;
}

interface LinkedFilter extends BaseFilter {
  type: 'linked';
  filters: ListFilter[];
}

type SerializableFilter = ListFilter | BoolFilter;
type CompositeFilter = ListFilter | BoolFilter | RangeFilter | LinkedFilter;

class CategoryFilters {
  filters: CompositeFilter[];
  sparseFilters: SerializableFilter[];
  #searchableFilters: Map<string, SerializableFilter>;

  constructor(filters: CompositeFilter[], sparseFilters: SerializableFilter[]) {
    this.filters = filters;
    this.sparseFilters = sparseFilters;
    this.#searchableFilters = new Map(
      sparseFilters.map((filter) => {
        return [filter.queryString, filter];
      })
    );
  }

  filterForShortName(shortName: string): CompositeFilter {
    return this.#searchableFilters.get(shortName) as CompositeFilter;
  }
}

export { CategoryFilters };
export type {
  SerializableFilter,
  CompositeFilter,
  ListFilter,
  BoolFilter,
  RangeFilter,
  LinkedFilter,
};
