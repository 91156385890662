import { captureException } from '@sentry/nextjs';
import { CategoryFilters } from '@shared/models';
import store from './filter-store';

const configCache: Map<string, CategoryFilters | null> = new Map([
  // Start with category 0 preloaded and empty: it does not exist in the config.
  ['0_s', null],
  ['0_k', null],
  ['0_u', null],
  ['0_h', null],
  ['0_g', null],
]);

const filtersForCategoryConfig = (
  category: string,
  adType: string
): CategoryFilters | null => {
  const specificCategory = `${category}_${adType}`;

  if (configCache.has(specificCategory)) {
    return configCache.get(specificCategory) || null;
  } else {
    try {
      const config = store.configurations[specificCategory];

      const categoryFilters = new CategoryFilters(
        config.filters,
        config.sparseFilters
      );

      // This method is called many times, so we cache the result
      configCache.set(specificCategory, categoryFilters);

      return categoryFilters;
    } catch {
      captureException(
        new Error(
          `Could not retrieve the filters config for ${specificCategory}`
        ),
        { level: 'error' }
      );
      return null;
    }
  }
};

export { filtersForCategoryConfig as getFiltersConfigStore };
