import { default as bodyValuesToName } from './body-from-param';
import { default as fuelValuesToName } from './fuel-from-param';
import { default as makeValuesToName } from './make-from-param';
import { default as modelValuesToName } from './model-from-param';

/**
 * The list of parameters to direct in the order they should fill the path slots.
 */
const parametersToRedirect = ['cb', 'cm', 'cart', 'fu'];

const parameterToValue = new Map<string, Map<string, string>>([
  ['cb', makeValuesToName],
  ['cm', modelValuesToName],
  ['cart', bodyValuesToName],
  ['fu', fuelValuesToName],
]);

export {
  bodyValuesToName,
  fuelValuesToName,
  makeValuesToName,
  modelValuesToName,
  parameterToValue,
  parametersToRedirect,
};
