import { HTTPStatusCode } from '@sbt-web/networking';
import type { Response } from 'express';

type ErrorSeverity = 'FATAL' | 'WARN';

function registerError(
  res: Response | undefined,
  status: HTTPStatusCode,
  severity: ErrorSeverity,
  additionalData: {
    message: string | Error;
    service: string;
    [k: string]: unknown;
  }
): void {
  if (!res || status === HTTPStatusCode.OK) {
    return;
  }

  if (additionalData.message instanceof Error) {
    additionalData.message = additionalData.message.toString();
  }

  res.locals.errors = res.locals.errors || [];
  res.locals.errors.push({ severity, status, ...additionalData });
}

function hasFatalErrors(res?: Response): boolean {
  if (!res) {
    return false;
  }

  return (
    res.statusCode !== HTTPStatusCode.OK ||
    (!!res.locals.errors &&
      res.locals.errors.some(
        (e: { severity: ErrorSeverity }) => e.severity === 'FATAL'
      ))
  );
}

function hasTimeoutErrors(res?: Response): boolean {
  if (!res) {
    return false;
  }

  return (
    res.locals.errors !== undefined &&
    res.locals.errors.some(
      (e: { status: HTTPStatusCode }) =>
        e.status === HTTPStatusCode.GatewayTimeout
    )
  );
}

const registerSeoError = (
  res: Response | undefined,
  service: string,
  e?: unknown
): void => {
  registerError(res, HTTPStatusCode.InternalServerError, 'WARN', {
    message: `Unhandled error: ${e}`,
    service,
  });
};

const registerSeoTimeoutError = (
  res: Response | undefined,
  service: string
): null => {
  registerError(res, HTTPStatusCode.GatewayTimeout, 'WARN', {
    message: new Error('Timeout in a SEO call'),
    service,
  });

  return null;
};

const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export {
  registerError,
  hasFatalErrors,
  hasTimeoutErrors,
  registerSeoError,
  registerSeoTimeoutError,
  getErrorMessage,
};
export type { ErrorSeverity };
