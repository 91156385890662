import { assertNever } from '@sbt-web/utils';

interface InclusionMatcherKind<T> {
  kind: string;
  elements: T[];
}

class IncludedMatcher<T> implements InclusionMatcherKind<T> {
  kind = 'IncludedMatcher' as const;
  constructor(public elements: T[]) {}
}

class ExcludedMatcher<T> implements InclusionMatcherKind<T> {
  kind = 'ExcludedMatcher' as const;
  constructor(public elements: T[]) {}
}

export type InclusionMatcher<T> = IncludedMatcher<T> | ExcludedMatcher<T>;

/**
 * Create an exclude matcher with the provided items list.
 * @param t the list of excluded items.
 */
export function exclude<T>(...t: T[]): InclusionMatcher<T> {
  return new ExcludedMatcher(t);
}

/**
 * Create an include matcher with the provided items list.
 * @param t the list of included items.
 */
export function include<T>(...t: T[]): InclusionMatcher<T> {
  return new IncludedMatcher(t);
}

/**
 * Create a matcher that includes every item.
 * @param t the list of included items
 */
export function all<T>(): InclusionMatcher<T> {
  return new ExcludedMatcher([]);
}

/**
 * Return whether an item match the provided matcher.
 * @param inclusion the matcher
 * @param t the item to be matched
 */
export function matches<T>(inclusion: InclusionMatcher<T>, t: T): boolean {
  switch (inclusion.kind) {
    case 'IncludedMatcher': {
      return inclusion.elements.includes(t);
    }
    case 'ExcludedMatcher': {
      return !inclusion.elements.includes(t);
    }
    default:
      assertNever(inclusion);
  }
}
