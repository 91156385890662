import {
  Action,
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from 'redux';
import thunk from 'redux-thunk';
import { reducer, RootState } from './';
import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';

let store: Store<RootState> | undefined = undefined;

export const getStore = (
  state: RootState | undefined,
  isServer?: boolean
): Store<RootState> => {
  if (isServer) {
    return createStore<RootState, Action<unknown>, undefined, undefined>(
      reducer,
      state,
      applyMiddleware(thunk) as unknown as StoreEnhancer<undefined>
    );
  } else {
    const composeEnhancers =
      (process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose;

    if (!store) {
      const mw = [thunk];
      if (process.env.NODE_ENV === 'production') {
        if (window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = () => undefined;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { createLogger } = require('redux-logger');

        mw.push(
          createLogger({
            predicate: (_: void, action: Action) =>
              !action.type.startsWith('@@'),
            collapsed: true,
          })
        );
      }

      markPerformance(Marks.CREATE_STORE);
      store = createStore<RootState, Action<unknown>, undefined, undefined>(
        reducer,
        state,
        composeEnhancers(applyMiddleware(...mw))
      );
    }

    return store;
  }
};
