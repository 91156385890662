import {
  ShortConfiguration,
  AdSenseVariations,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-before-listing-lp-tutti',
  Jobs = 'desktop-before-listing-lp-jobs',
  Motor = 'desktop-before-listing-lp-motor',
  Market = 'desktop-before-listing-lp-market',
  RealEstate = 'desktop-before-listing-lp-realestate',
}

const desktopBeforeListingDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '2500150971',
    channel: 'DESKTOP-TOP-LP-TUTTI-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopBeforeListingJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '5085313203',
    channel: 'DESKTOP-TOP-LP-JOBS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopBeforeListingMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '8159650232',
    channel: 'DESKTOP-TOP-LP-MOTORS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopBeforeListingMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '3772230900',
    channel: 'DESKTOP-TOP-LP-MARKET-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopBeforeListingRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '6398395506',
    channel: 'DESKTOP-TOP-LP-REALESTATE-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopBeforeListing: Array<ShortConfiguration> = [
  desktopBeforeListingDefault,
  desktopBeforeListingJob,
  desktopBeforeListingMotor,
  desktopBeforeListingMarket,
  desktopBeforeListingRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export {
  desktopBeforeListing,
  IDs as DesktopBeforeListingIDs,
  variations as DesktopBeforeListing,
};
