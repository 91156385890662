export default new Map<string, string>([
  ['1', 'utilitaria'],
  ['2', 'berlina'],
  ['3', 'station-wagon'],
  ['4', 'monovolume'],
  ['5', 'suv-fuoristrada'],
  ['6', 'cabrio'],
  ['7', 'coupe'],
  ['8', 'city-car'],
  ['10', 'altro'],
]);
