import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-7',
  Jobs = 'desktop-middle-lp-jobs-7',
  Motor = 'desktop-middle-lp-motor-7',
  Market = 'desktop-middle-lp-market-7',
  RealEstate = 'desktop-middle-lp-realestate-7',
}

const desktopMiddle7Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_TUTTI,
    channel: 'DESKTOP-MIDDLE-LP-TUTTI-7-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle7Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_JOBS,
    channel: 'DESKTOP-MIDDLE-LP-JOBS-7-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle7Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MOTORS,
    channel: 'DESKTOP-MIDDLE-LP-MOTORS-7-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle7Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MARKET,
    channel: 'DESKTOP-MIDDLE-LP-MARKET-7-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle7RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_REALESTATE,
    channel: 'DESKTOP-MIDDLE-LP-REALESTATE-7-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle7: Array<ShortConfiguration> = [
  desktopMiddle7Default,
  desktopMiddle7Job,
  desktopMiddle7Motor,
  desktopMiddle7Market,
  desktopMiddle7RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopMiddle7, variations as DesktopMiddle7 };
