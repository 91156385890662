import {
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-1',
  Jobs = 'desktop-middle-lp-jobs-1',
  Motor = 'desktop-middle-lp-motor-1',
  Market = 'desktop-middle-lp-market-1',
  RealEstate = 'desktop-middle-lp-realestate-1',
}

const desktopMiddle1Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '2395467975',
    channel: 'DESKTOP-MIDDLE-LP-TUTTI-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle1Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '5230107329',
    channel: 'DESKTOP-MIDDLE-LP-JOBS-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle1Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '7856271935',
    channel: 'DESKTOP-MIDDLE-LP-MOTORS-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle1Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '1146066294',
    channel: 'DESKTOP-MIDDLE-LP-MARKET-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle1RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '9169354238',
    channel: 'DESKTOP-MIDDLE-LP-REALESTATE-1-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle1: Array<ShortConfiguration> = [
  desktopMiddle1Default,
  desktopMiddle1Job,
  desktopMiddle1Motor,
  desktopMiddle1Market,
  desktopMiddle1RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopMiddle1, variations as DesktopMiddle1 };
