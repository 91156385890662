import {
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-5',
  Jobs = 'desktop-middle-lp-jobs-5',
  Motor = 'desktop-middle-lp-motor-5',
  Market = 'desktop-middle-lp-market-5',
  RealEstate = 'desktop-middle-lp-realestate-5',
}

const desktopMiddle5Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '9122937311',
    channel: 'DESKTOP-MIDDLE-LP-TUTTI-5-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle5Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '8256916069',
    channel: 'DESKTOP-MIDDLE-LP-JOBS-5-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle5Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '3487455857',
    channel: 'DESKTOP-MIDDLE-LP-MOTORS-5-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle5Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '3946527168',
    channel: 'DESKTOP-MIDDLE-LP-MARKET-5-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle5RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '1052867372',
    channel: 'DESKTOP-MIDDLE-LP-REALESTATE-5-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle5: Array<ShortConfiguration> = [
  desktopMiddle5Default,
  desktopMiddle5Job,
  desktopMiddle5Motor,
  desktopMiddle5Market,
  desktopMiddle5RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopMiddle5, variations as DesktopMiddle5 };
