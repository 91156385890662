import { createContext } from 'react';
import { SkyscraperData } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';

export type AdvDetailContextType = {
  showBannerBelowGallery: boolean;
  skyscraperData: SkyscraperData;
};
const AdvDetailContext = createContext<AdvDetailContextType>({
  showBannerBelowGallery: false,
  skyscraperData: {},
});

export { AdvDetailContext };
