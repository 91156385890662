import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-4',
  Jobs = 'desktop-middle-lp-jobs-4',
  Motor = 'desktop-middle-lp-motor-4',
  Market = 'desktop-middle-lp-market-4',
  RealEstate = 'desktop-middle-lp-realestate-4',
}

const desktopMiddle4Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_TUTTI,
    channel: 'DESKTOP-MIDDLE-LP-TUTTI-4-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle4Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_JOBS,
    channel: 'DESKTOP-MIDDLE-LP-JOBS-4-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle4Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MOTORS,
    channel: 'DESKTOP-MIDDLE-LP-MOTORS-4-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle4Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MARKET,
    channel: 'DESKTOP-MIDDLE-LP-MARKET-4-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle4RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_REALESTATE,
    channel: 'DESKTOP-MIDDLE-LP-REALESTATE-4-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle4: Array<ShortConfiguration> = [
  desktopMiddle4Default,
  desktopMiddle4Job,
  desktopMiddle4Motor,
  desktopMiddle4Market,
  desktopMiddle4RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopMiddle4, variations as DesktopMiddle4 };
