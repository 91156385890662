export function withSpaces(str: string): string {
  return str ? `${str} ` : '';
}

export function formatWithSpaces(...chunks: string[]): string {
  return chunks
    .map((chunk) => withSpaces(chunk))
    .reduce((acc, chunk) => acc + chunk, '')
    .trim();
}

export function capitalizeFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeOnlyFirst(str: string): string {
  return capitalizeFirst(str.toLowerCase());
}
