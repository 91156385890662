import { createRef } from 'react';
import { NotificationsContainer } from '@sbt-web/ui';

const NotificationsRef = createRef<NotificationsContainer>();

const sendNotification: NotificationsContainer['push'] = (text, type) => {
  if (NotificationsRef.current) {
    NotificationsRef.current.push(text, type);
  }
};

export { NotificationsRef, sendNotification };
