import { SearchState } from '@reducers/search';
import { adTypesShortLabelsMap } from '@tools/search/values';
import { formatWithSpaces } from './common';

export function getVehiclesLabel(
  search: SearchState,
  adTypePrefix = 'usata '
): string {
  const adType = adTypesShortLabelsMap[search.adType] || 'vendita';
  const adTypeLabel = `${adTypePrefix}in ${adType.toLowerCase()}`;
  const brand = search.filtersLabels['cb'] || search.filtersLabels['bb'];
  const model = search.filtersLabels['cm'] || search.filtersLabels['bm'];
  const type = search.filtersLabels['ct'] || search.filtersLabels['mt'];
  const fuel = search.filtersLabels['fl'];

  const brandLabel = brand || '';
  const modelLabel = model || '';
  const typeLabel = type || '';
  const fuelLabel = fuel || '';

  if (!brand && !model && !type && fuel) {
    return formatWithSpaces('Auto', fuelLabel, adTypeLabel);
  }

  if (brand || model || type || fuel) {
    return formatWithSpaces(
      brandLabel,
      modelLabel,
      typeLabel,
      fuelLabel,
      adTypeLabel
    );
  }

  return '';
}

export function mapActualQueryStringToSEO(queryString: string): string {
  const seoQueryString = {
    ct: 'cart',
    fl: 'fu',
  }[queryString];

  return seoQueryString ? seoQueryString : queryString;
}
