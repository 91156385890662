import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { getPrerollAdUnit } from '@sbt-web/adv';

const DynamicVideoPreroll = dynamic(() => import('./VideoPreroll'), {
  ssr: false,
});

export const LazyVideoPreroll = function ({
  parentRef,
}: {
  parentRef: React.RefObject<HTMLElement>;
}) {
  const [prerollAdUnit, setPrerollAdUnit] = useState(undefined);

  React.useEffect(() => {
    setPrerollAdUnit(getPrerollAdUnit());
  }, []);

  return prerollAdUnit ? (
    <DynamicVideoPreroll parentRef={parentRef} prerollAdUnit={prerollAdUnit} />
  ) : null;
};
