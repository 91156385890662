const ADSENSE_STYLES = {
  // Mobile
  NO_SHOPPING_MOBILE_SEARCH_LIGHT_TUTTI: '3458979137',
  NO_SHOPPING_MOBILE_SEARCH_DARK_TUTTI: '7258473326',
  MOBILE_SHOPPING_LIGHT_TUTTI: '1403465207',
  MOBILE_SHOPPING_DARK_TUTTI: '5945391023',
  NO_SHOPPING_MOBILE_SEARCH_LIGHT_MARKET: '9090382904',
  NO_SHOPPING_MOBILE_SEARCH_DARK_MARKET: '4632308720',
  MOBILE_SHOPPING_LIGHT_MARKET: '2145896834',
  MOBILE_SHOPPING_DARK_MARKET: '7777300601',
  NO_SHOPPING_MOBILE_SEARCH_LIGHT_JOBS: '9832814531',
  NO_SHOPPING_MOBILE_SEARCH_DARK_JOBS: '5131340825',
  MOBILE_SHOPPING_LIGHT_JOBS: '1691348832',
  MOBILE_SHOPPING_DARK_JOBS: '2321978283',
  NO_SHOPPING_MOBILE_SEARCH_LIGHT_MOTORS: '3319226417',
  NO_SHOPPING_MOBILE_SEARCH_DARK_MOTORS: '9780991130',
  MOBILE_SHOPPING_LIGHT_MOTORS: '6752101923',
  MOBILE_SHOPPING_DARK_MOTORS: '4125937317',
  NO_SHOPPING_MOBILE_SEARCH_LIGHT_REALESTATE: '5586111638',
  NO_SHOPPING_MOBILE_SEARCH_DARK_REALESTATE: '2505176219',
  MOBILE_SHOPPING_LIGHT_REALESTATE: '5151135995',
  MOBILE_SHOPPING_DARK_REALESTATE: '7873608105',

  // Desktop
  NO_SHOPPING_DESKTOP_SEARCH_LIGHT_TUTTI: '5929896351',
  DESKTOP_SHOPPING_LIGHT_TUTTI: '1008895980',
  NO_SHOPPING_DESKTOP_SEARCH_LIGHT_MARKET: '2524971389',
  DESKTOP_SHOPPING_LIGHT_MARKET: '4616814048',
  NO_SHOPPING_DESKTOP_SEARCH_LIGHT_JOBS: '5247443499',
  DESKTOP_SHOPPING_LIGHT_JOBS: '1990649442',
  NO_SHOPPING_DESKTOP_SEARCH_LIGHT_MOTORS: '9677567139',
  DESKTOP_SHOPPING_LIGHT_MOTORS: '9693061811',
  NO_SHOPPING_DESKTOP_SEARCH_LIGHT_REALESTATE: '6324465578',
  DESKTOP_SHOPPING_LIGHT_REALESTATE: '9333782426',
};

export { ADSENSE_STYLES };
