import { DEFAULT_GALLERY_ITEMS_POS } from '@shared/constants';

interface PositionKind {
  kind: string;

  /**
   * Return the position if it is valid taking into account the page ads count,
   * undefined otherwise.
   */
  computeValue: (pageAdsCount: number) => number | undefined;
}

class BeforePosition implements PositionKind {
  kind = 'BeforePosition' as const;
  constructor(
    public computeValue: (pageAdsCount: number) => number | undefined
  ) {}
}

class AfterPosition implements PositionKind {
  kind = 'AfterPosition' as const;
  constructor(
    public computeValue: (pageAdsCount: number) => number | undefined
  ) {}
}

export type Position = BeforePosition | AfterPosition;

const defaultComputeValue =
  (position: number): ((c: number) => number | undefined) =>
  (pageAdsCount) => {
    if (position >= 0 && position <= pageAdsCount) {
      return position;
    }
    return undefined;
  };

/**
 * Create a before position.
 */
export function before(position: number): Position {
  return new BeforePosition(defaultComputeValue(position));
}

/**
 * Create an after position.
 */
export function after(position: number): Position {
  return new AfterPosition(defaultComputeValue(position));
}

const afterLastAdComputeValue = (pageAdsCount: number): number => pageAdsCount;

/**
 * Create a position at the end of the listing.
 */
export function endOfListing(): Position {
  return new AfterPosition(afterLastAdComputeValue);
}

const afterGalleryComputeValue = (pageAdsCount: number): number =>
  pageAdsCount < DEFAULT_GALLERY_ITEMS_POS
    ? pageAdsCount
    : DEFAULT_GALLERY_ITEMS_POS;

/**
 * Create a position after the default gallery position, or after the
 * position of the last ad if the ads count is less then the default
 * gallery position.
 */
export function galleryPosition(): Position {
  return new AfterPosition(afterGalleryComputeValue);
}
