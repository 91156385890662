import { ListingPlugin, SmartADVItem } from '@shared/models';
import { all, exclude, include } from '@shared/models/ListingPlugins/matchers';
import { after } from '@shared/models/ListingPlugins/position';
import {
  ADSENSE_DESKTOP_STYLE,
  DesktopGAMIds,
  GAM_DESKTOP_STYLE,
  CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE,
} from './commons';
import { DesktopMiddle2 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle2';
import { DesktopMiddle1 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle1';
import { DesktopMiddle3 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle3';
import { DesktopMiddle4 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle4';
import { DesktopMiddle5 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle5';
import { DesktopMiddle6 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle6';
import { DesktopMiddle7 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle7';
import { DesktopBeforeListing } from '@client/components/Adv/AdSense/listing/adUnits/desktop-before-listing';

const desktopAdvEvery3: Array<ListingPlugin> = [
  // --- After the first Ad
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopBeforeListing,
        gamFallback: DesktopGAMIds.FALLBACK1,
      },
      null
    ),
    categories: all(),
    position: after(1),
  },
  // --- Then after two Ads
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        id: DesktopGAMIds.NATIVE1,
        // This placement near the fold has 200px to reduce the CLS with native
        // placement (retail media) or with big cards.
        style: { ...GAM_DESKTOP_STYLE, minHeight: '200px' },
      },
      null
    ),
    categories: all(),
    position: after(3),
  },
  // --- Then every 3 Ads
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopMiddle1,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE1,
      },
      null
    ),
    categories: all(),
    position: after(6),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE2,
      },
      null
    ),
    categories: all(),
    position: after(9),
  },
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopMiddle2,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE2,
      },
      null
    ),
    categories: all(),
    position: after(12),
  },

  //Position 15:
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle3,
        gamFallback: DesktopGAMIds.NATIVE3,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(15),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE3,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(15),
  },

  //Position 18:
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle4,
        gamFallback: DesktopGAMIds.NATIVE4,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(18),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE4,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(18),
  },

  //Position 21:
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle5,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE3,
      },
      null
    ),
    categories: all(),
    position: after(21),
  },

  //Position 24:
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle6,
        gamFallback: DesktopGAMIds.NATIVE5,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(24),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE5,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(24),
  },

  //Position 27:
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle7,
        gamFallback: DesktopGAMIds.NATIVE6,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(27),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE6,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(27),
  },
];

export { desktopAdvEvery3 };
