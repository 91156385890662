import type { ItemFeature } from '@sbt-web/networking';

const parsePrice = (priceFeature: ItemFeature | null): number | null => {
  if (priceFeature != null) {
    return parseFloat(priceFeature.values[0].key);
  } else {
    return null;
  }
};

export default parsePrice;
