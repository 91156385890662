import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-box-tutti-2',
  Jobs = 'msite-middle-lp-jobs-2',
  Motor = 'msite-middle-lp-motor-2',
  Market = 'msite-middle-lp-market-2',
  RealEstate = 'msite-middle-lp-realestate-2',
}

const msiteMiddle1Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_TUTTI,
    channel: 'MSITE-MIDDLE-LP-TUTTI-2-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_JOBS,
    channel: 'MSITE-MIDDLE-LP-JOBS-2-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MOTORS,
    channel: 'MSITE-MIDDLE-LP-MOTORS-2-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MARKET,
    channel: 'MSITE-MIDDLE-LP-MARKET-2-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle1RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_REALESTATE,
    channel: 'MSITE-MIDDLE-LP-REALESTATE-2-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle2: Array<ShortConfiguration> = [
  msiteMiddle1Default,
  msiteMiddle1Job,
  msiteMiddle1Motor,
  msiteMiddle1Market,
  msiteMiddle1RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { msiteMiddle2, variations as MsiteMiddle2 };
