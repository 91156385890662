enum MsiteAdDetailGAMIds {
  BOX1 = 'gpt-msite-adview-1',
  BOX2 = 'apn_adview_bottom',
  ANCHOR = 'apn_adview_anchor',
}

enum DesktopAdDetailGAMIds {
  BILLBOARD = 'apn_top_tag',
  LEFT_SKYSCRAPER = 'apn_skyscraper_tag_left',
  RIGHT_SKYSCRAPER = 'apn_skyscraper_tag_right',
  BELOW_GALLERY = 'apn_below_gallery',
  SKIN = 'apn_skin_tag',
}

enum DesktopListingGAMIds {
  BILLBOARD = 'apn_top_tag',
}

export { MsiteAdDetailGAMIds, DesktopListingGAMIds, DesktopAdDetailGAMIds };
