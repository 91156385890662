export enum Marks {
  GLOBAL_RUN = 'run-global-addetail-script',
  GLOBAL_RUN_APP = 'run-global-app-script',
  DATA_LAYER_IS_READY = 'data_layer_ready',
  CREATE_STORE = 'create_store',
  AD_DETAILS_USE_EFFECT = 'ad_details_use_effect',
  PERFORMANCE_MARK_OPENWRAP_BIDDING_START = 'adv-openwrap-bidding-started',
}

const scriptMarkers: PerformanceEntry[] = [];
const longTasks: Array<PerformanceEntry> = [];
function checkMarkerInLongTaskInterval() {
  longTasks.forEach((longTask, longTaskIndex) => {
    scriptMarkers.forEach((scriptMarker, scriptMarkerIndex) => {
      if (
        longTask.startTime <= scriptMarker.startTime &&
        scriptMarker.startTime <= longTask.startTime + longTask.duration
      ) {
        markWithLongTasks[scriptMarker.name] = longTask;
        longTasks.splice(longTaskIndex, 1);
        scriptMarkers.splice(scriptMarkerIndex, 1);
      }
    });
  });
}

export const markWithLongTasks: { [key: string]: PerformanceEntry } = {};

export const markPerformance = function (mark: Marks): void {
  if (typeof window !== 'undefined') {
    scriptMarkers.push(window.performance?.mark?.(mark));
  }
};

export const logPerformance = function (): void {
  const orderedMarks: Array<{ key: string; duration: number }> = [];
  Object.values(Marks).forEach((mark) => {
    // eslint-disable-next-line compat/compat
    const markEntry = window.performance.getEntriesByName(mark, 'mark')[0];

    if (markEntry) {
      const m = window.performance?.measure(mark, undefined, mark);
      orderedMarks.push({ key: markEntry.name, duration: m.duration });
    }
  });
  orderedMarks.sort((a, b) => {
    if (a.duration < b.duration) {
      return -1;
    }
    if (a.duration > b.duration) {
      return 1;
    }
    return 0;
  });
  orderedMarks.map((mark) =>
    console.log('PERFORMANCE MARK:', mark.key, mark.duration)
  );
};

if (typeof window !== 'undefined' && window.PerformanceObserver) {
  // Observe long tasks:
  const observer = new window.PerformanceObserver((list) => {
    const perfEntries = list.getEntries();
    perfEntries.forEach((entry) => {
      longTasks.push(entry);
      checkMarkerInLongTaskInterval();
    });
  });
  observer.observe({ entryTypes: ['longtask'] });
}
