import { CategoryId } from '@sbt-web/networking';
import {
  parametersToRedirect as appartamentiParametersToRedirect,
  parameterToValue as appartamentiParametersToValue,
} from './redirection-maps/appartamenti';
import {
  parametersToRedirect as autoParametersToRedirect,
  parameterToValue as autoParametersToValue,
} from './redirection-maps/auto';

import {
  parametersToRedirect as abbigliamentoParametersToRedirect,
  parameterToValue as abbigliamentoParametersToValue,
} from './redirection-maps/abbigliamento-e-accessori';

/**
 * Maps a category to the array of redirectable parameters
 */
const categoryToParameters = new Map<CategoryId, string[]>([
  [CategoryId.Auto, autoParametersToRedirect],
  [CategoryId.Appartamenti, appartamentiParametersToRedirect],
  [CategoryId.AbbigliamentoAccessori, abbigliamentoParametersToRedirect],
]);

/**
 * Maps a category to another map, which maps the individual
 * redirectable parameter to its possible values.
 */
const categoryToParameterValueMap = new Map<
  CategoryId,
  Map<string, Map<string, string>>
>([
  [CategoryId.Auto, autoParametersToValue],
  [CategoryId.Appartamenti, appartamentiParametersToValue],
  [CategoryId.AbbigliamentoAccessori, abbigliamentoParametersToValue],
]);

/**
 * Checks if a query in a given category contains parameters which can be redirected.
 */
const queryHasRedirectableParameters = (
  category: CategoryId,
  params: URLSearchParams
): boolean => {
  // If the category is supported
  if (categoryToParameters.has(category)) {
    // Get the params of that category which can be redirected
    const orderedPathParams = categoryToParameters.get(category);

    // Then the intersection of the possible parameters
    // and the parameters in the query
    // (i.e. the redirectable parameters we really have)
    const includedParams = orderedPathParams?.filter((param) =>
      params.has(param)
    );

    // If there is at least one redirectable param in the query
    if (includedParams && includedParams.length > 0) {
      // Retrieve the supported values for that parameter (for the category)
      return includedParams.some((param) => {
        const supportedValues = categoryToParameterValueMap
          .get(category)
          ?.get(param);

        // Return whether the value in the query is mappable to a path part
        // If any are, we allow the redirect.
        const value = params.get(param);
        if (value) {
          return supportedValues?.has(value);
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * Joins the parts of a path together by slashes, dropping all undefined pieces
 * and adding a trailing slash.
 * @param pathParts The ordered parts of the path to build.
 * @returns the path, joined by slashes and with a trailing slash.
 */
const formatPathParts = (pathParts: (string | undefined)[]): string => {
  const partsToJoin = pathParts.filter((part) => part != undefined);

  if (partsToJoin.length > 0) {
    const joinedPath = partsToJoin.join('/');

    return `${joinedPath}/`;
  } else {
    return '';
  }
};

/**
 * Converts query string parameters into a URL path, removing the parameters
 * from the param collection and returns both. The param collection is a new
 * instance: the parameter passed is not mutated.
 * @param category The category being searched in
 * @param params The URLQueryParams generated from the search state. This
 * will not be mutated
 * @returns The path build from the converted query, with a trailing slash;
 * and the query params with the values mapped to the path removed.
 */
const rewriteQuery = (
  category: CategoryId,
  params: URLSearchParams
): { path: string; rewrittenQuery: URLSearchParams } => {
  // Validate that the map contains a result in order to refine the type.
  if (categoryToParameters.has(category)) {
    // Create a new collection of params.
    const localQuery = new URLSearchParams(params);

    // Get the ordered path parts
    const orderedPathParams = categoryToParameters.get(category);
    // And the map of parameter values to their friendly names
    const parameterToValue = categoryToParameterValueMap.get(category);

    // Extract all parts which should be in the path, and remove them from
    // the URLQueryParams.
    const pathParts = orderedPathParams?.map((parameter) => {
      if (localQuery.has(parameter)) {
        // Get the value in the query itself
        const queryValue = localQuery.get(parameter) || '';

        // And the name associated with that value for the path
        const valueNameForPath = parameterToValue
          ?.get(parameter)
          ?.get(queryValue);

        // If the value is mappable, map it.
        // Not all values (e.g. in apartments) are mapped.
        if (valueNameForPath !== undefined) {
          localQuery.delete(parameter);

          return valueNameForPath;
        } else {
          // If the value is not supported, leave it in the query string
          return undefined;
        }
      } else {
        return undefined;
      }
    });

    return {
      path: pathParts ? formatPathParts(pathParts) : '',
      rewrittenQuery: localQuery,
    };
  } else {
    // This case should not occur but allows safe failure.
    return { path: '', rewrittenQuery: params };
  }
};

export { queryHasRedirectableParameters, rewriteQuery };
