import { AdItem, CategoryId } from '@sbt-web/networking';
import type { PageType } from '@sbt-web/adv';
import type { ENV } from '@sbt-web/utils';
import type { AdUnitSize } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';

let intervalId: undefined | number = undefined;

export const reloadAdvWithDefaultUnits = async (
  isDesktop: boolean,
  pageType: PageType,
  categoryId: CategoryId,
  env: ENV,
  ad?: AdItem,
  advDetailGalleryToggle?: boolean,
  isInterstitialDesktopEnabled?: boolean,
  skyscraperSizes?: Array<AdUnitSize>,
  advDesktopBelowGalleryToggle?: boolean
): Promise<void> => {
  // try to avoid errors on sentry due to AdBlockers
  if (!window.subitoAdv?.gpt) {
    return;
  }

  window.subitoAdv.gpt.useDefaultAdUnits({
    pageType,
    categoryId,
    isDesktop,
    env,
    ad,
    advDetailGalleryToggle,
    isInterstitialDesktopEnabled,
    polarisSkyscrapers: skyscraperSizes,
    advDetailBelowGalleryToggle: advDesktopBelowGalleryToggle,
  });
  await window.subitoAdv.gpt.reloadAdv();
};

export const initAdv = async function (
  pageType: PageType,
  categoryId: CategoryId,
  env: ENV,
  ad?: AdItem,
  advDetailGalleryToggle?: boolean,
  isInterstitialDesktopEnabled?: boolean,
  skyscraperSizes?: Array<AdUnitSize>,
  advDesktopBelowGalleryToggle?: boolean
): Promise<void> {
  // try to avoid errors on sentry due to AdBlockers
  if (!window.subitoAdv?.gpt) {
    return;
  }

  const mediaQueryList: MediaQueryList =
    window.matchMedia('(min-width: 980px)');

  //Run ADV:
  window.subitoAdv.gpt.useDefaultAdUnits({
    pageType,
    categoryId,
    isDesktop: mediaQueryList.matches,
    env,
    ad,
    advDetailGalleryToggle,
    advDetailBelowGalleryToggle: advDesktopBelowGalleryToggle,
    isInterstitialDesktopEnabled,
    polarisSkyscrapers: skyscraperSizes?.length ? skyscraperSizes : undefined,
  });

  await window.subitoAdv.gpt.runAdv();

  //Refresh every 30 seconds:
  if (!intervalId) {
    intervalId = window.setInterval(
      window.subitoAdv.gpt.reloadRefreshableAdUnits.bind(window.subitoAdv.gpt),
      30000
    );
  }

  //On media type change:
  const onChange = (): void => {
    reloadAdvWithDefaultUnits(
      mediaQueryList.matches,
      pageType,
      categoryId,
      env,
      ad,
      advDetailGalleryToggle,
      isInterstitialDesktopEnabled,
      skyscraperSizes,
      advDesktopBelowGalleryToggle
    );
  };
  mediaQueryList.addEventListener('change', onChange);
};

export const runAdv = function (
  pageType: PageType,
  categoryId: CategoryId,
  env: ENV,
  ad?: AdItem,
  advDetailGalleryToggle?: boolean,
  isInterstitialDesktopEnabled?: boolean,
  skyscraperSizes?: Array<AdUnitSize>,
  advDesktopBelowGalleryToggle?: boolean
): Promise<void> {
  return new Promise((r) => {
    window.subitoAdvQueue.push(async () => {
      await initAdv(
        pageType,
        categoryId,
        env,
        ad,
        advDetailGalleryToggle,
        isInterstitialDesktopEnabled,
        skyscraperSizes,
        advDesktopBelowGalleryToggle
      );
      r();
    });
  });
};
