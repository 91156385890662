import type { RootState } from '@reducers/index';
import type { UserState } from '@reducers/user';
import { PublicUser } from '@sbt-web/auth';
import Header from '@sbt-web/header';
import type { ENV } from '@sbt-web/utils';
import { houstonEnvMap } from '@shared/constants';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React, { useEffect, useState, type FunctionComponent } from 'react';
import { connect } from 'react-redux';

import '@sbt-web/header/style';

interface StateProps {
  user: UserState;
}

interface OwnProps {
  section: 'adview' | 'listing';
  theme: string;
}

type Props = StateProps & OwnProps;

const themeMap = new Map<string, string>([
  ['blueMotori', 'motori'],
  ['redCorporate', 'corporate'],
  ['greenLavoro', 'lavoro'],
  ['purpleImmobili', 'immobili'],
  ['yellowMarket', 'market'],
]);

/**
 * A constant for the unlogged user.
 * Using a constant reference might save re-renders.
 */
const emptyUser = new PublicUser('', '', '');

const HeaderComponent: FunctionComponent<Props> = ({
  user,
  section,
  theme,
}) => {
  const headerTheme = themeMap.get(theme) ?? 'corporate';

  // On the server-side we never have the user data
  const [userDetails, setUserDetails] = useState<PublicUser>(emptyUser);

  useEffect(() => {
    if (user.data) {
      setUserDetails(user.data);
    }
  }, [user]);

  return (
    <Header
      section={section}
      theme={headerTheme}
      urlPrefixes={{
        assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
        baseURL: process.env.NEXT_PUBLIC_ENV_BASE_URL,
        phpBase: process.env.NEXT_PUBLIC_PHP_BASE_URL,
        reservedArea: process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL,
        shopBase: process.env.NEXT_PUBLIC_IMPRESAPIU_BASE_URL,
      }}
      user={userDetails}
      env={houstonEnvMap.get(
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV
      )}
      pulseInstance={getOrCreatePulse()}
    />
  );
};

export default connect<StateProps, unknown, unknown, RootState>((state) => ({
  user: state.user,
}))(HeaderComponent);
