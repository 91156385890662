import {
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-before-listing-lp-tutti',
  Jobs = 'msite-before-listing-lp-jobs',
  Motor = 'msite-before-listing-lp-motor',
  Market = 'msite-before-listing-lp-market',
  RealEstate = 'msite-before-listing-lp-realestate',
}

const msiteBeforeListingDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '5070414489',
    channel: 'MSITE-TOP-LP-TUTTI-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '7363024837',
    channel: 'MSITE-TOP-LP-JOBS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '2439874217',
    channel: 'MSITE-TOP-LP-MOTORS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '4495388147',
    channel: 'MSITE-TOP-LP-MARKET-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListingRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '5808470450',
    channel: 'MSITE-TOP-LP-REALESTATE-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteBeforeListing: Array<ShortConfiguration> = [
  msiteBeforeListingDefault,
  msiteBeforeListingJob,
  msiteBeforeListingMotor,
  msiteBeforeListingMarket,
  msiteBeforeListingRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export {
  msiteBeforeListing,
  IDs as MsiteBeforeListingIDs,
  variations as MsiteBeforeListing,
};
