export default new Map<string, string>([
  ['12', 'orologi-e-gioielli'],
  ['5', 'scarpe'],
  ['6', 'accessori'],
  ['2', 'giacche-e-giubbotti'],
  ['10', 'borse-e-zaini'],
  ['9', 'vestiti-e-completi'],
  ['7', 't-shirt-e-camicie'],
  ['1', 'felpe-e-maglioni'],
  ['4', 'pantaloni-e-jeans'],
  ['8', 'intimo-e-pigiami'],
  ['3', 'gonne'],
  ['11', 'altro'],
]);
