// Google Tag Manager
export const GTM_URL = 'https://www.googletagmanager.com/gtm.js?id=GTM-TWPJW6H';
export const GTM_STAGE_SUFFIX =
  '&gtm_auth=KOPoJMwiqaNuTvoLKOs7hA&gtm_preview=env-5';

// Network
export const HADES_PATH = '/hades';
export const WEB_API_CHANNEL = 'web';
export const MOBILE_API_CHANNEL = 'msite';

// Anubi
export const ADS_PER_PAGE = 30;
export const DEFAULT_GALLERY_ITEMS_POS = 5;
export const LISTING_PAGE_LIMIT = 300;

export const houstonEnvMap = new Map<
  'dev' | 'stage' | 'prod',
  'dev' | 'pre' | 'pro'
>([
  ['dev', 'pre'],
  ['stage', 'pre'],
  ['prod', 'pro'],
]);

export const DEFAULT_ADVERTISER_NAME = 'Utente';

//Share:
export const AD_SHARING_TITLE =
  'Questo annuncio su Subito potrebbe interessarti: ';
