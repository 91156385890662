import type {
  ClientHintsContextData,
  EffectiveConnectionType,
} from '@sbt-web/hooks';
import type { IncomingMessage } from 'http';

const getClientHints = (
  req?: IncomingMessage
): ClientHintsContextData | null => {
  if (!req) {
    return null;
  }

  const saveDataHeader = req.headers['save-data'];
  let saveData = false;
  if (Array.isArray(saveDataHeader)) {
    saveData = saveDataHeader.some(
      (saveDataValue) => saveDataValue.toLowerCase() === 'on'
    );
  } else if (saveDataHeader) {
    saveData = saveDataHeader.toLowerCase() === 'on';
  }

  let effectiveConnectionType: EffectiveConnectionType = '4g';
  const effectiveConnectionTypeHeader = req.headers['ect'];
  if (Array.isArray(effectiveConnectionTypeHeader)) {
    try {
      effectiveConnectionType =
        effectiveConnectionTypeHeader[0] as EffectiveConnectionType;
    } catch (e) {
      console.error('There was an error processing multiple ECT headers:', e);
    }
  } else if (effectiveConnectionTypeHeader) {
    effectiveConnectionType =
      effectiveConnectionTypeHeader as EffectiveConnectionType;
  }

  let deviceMemory: number | null = null;
  const deviceMemoryTypeHeader = req.headers['device-memory'];
  if (Array.isArray(deviceMemoryTypeHeader)) {
    try {
      const parsedMemory = Number.parseFloat(deviceMemoryTypeHeader[0]);

      deviceMemory = parsedMemory;
    } catch (e) {
      console.error(
        'There was an error processing multiple Device-Memory headers:',
        e
      );
    }
  } else if (deviceMemoryTypeHeader) {
    deviceMemory = Number.parseFloat(deviceMemoryTypeHeader);
  }

  return { saveData, effectiveConnectionType, deviceMemory };
};

export default getClientHints;
