import { default as balconyValuesToName } from './balcony-from-param';
import { default as conditionValuesToName } from './condition-from-param';
import { default as inserterValuesToName } from './inserter-from-param';

/**
 * The list of parameters to direct in the order they should fill the path slots.
 */
const parametersToRedirect = ['bc', 'advt', 'blc'];

const parameterToValue = new Map<string, Map<string, string>>([
  ['bc', conditionValuesToName],
  ['advt', inserterValuesToName],
  ['blc', balconyValuesToName],
]);

export {
  balconyValuesToName,
  conditionValuesToName,
  inserterValuesToName,
  parameterToValue,
  parametersToRedirect,
};
