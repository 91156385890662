export default new Map<string, string>([
  ['000000', 'altro'],
  ['000008', 'fiat'],
  ['000013', 'ferrari'],
  ['000018', 'dacia'],
  ['000020', 'opel'],
  ['000027', 'porsche'],
  ['000033', 'mercedes'],
  ['000034', 'lancia'],
  ['000035', 'autobianchi'],
  ['000039', 'ford'],
  ['000049', 'bertone'],
  ['000053', 'seat'],
  ['000062', 'renault'],
  ['000070', 'bentley'],
  ['000074', 'aston-martin'],
  ['000076', 'cadillac'],
  ['000080', 'austin-rover'],
  ['000081', 'buick'],
  ['000083', 'alfa-romeo'],
  ['000084', 'chevrolet'],
  ['000085', 'bmw'],
  ['000086', 'rolls-royce'],
  ['000087', 'chrysler'],
  ['000091', 'citroen'],
  ['000094', 'skoda'],
  ['000095', 'nissan-spagna'],
  ['000098', 'volvo'],
  ['000101', 'volkswagen'],
  ['000103', 'toyota'],
  ['000104', 'daihatsu'],
  ['000106', 'daimler'],
  ['000109', 'moretti'],
  ['000110', 'jaguar'],
  ['000113', 'oltcit'],
  ['000114', 'de-tomaso'],
  ['000117', 'maserati'],
  ['000120', 'innocenti'],
  ['000122', 'hyundai'],
  ['000128', 'fso'],
  ['000134', 'lotus'],
  ['000135', 'lamborghini'],
  ['000136', 'morgan'],
  ['000137', 'pontiac'],
  ['000140', 'land-rover'],
  ['000144', 'jeep'],
  ['000145', 'mazda'],
  ['000146', 'isuzu'],
  ['000147', 'honda'],
  ['000148', 'mitsubishi'],
  ['000149', 'nissan'],
  ['000151', 'peugeot'],
  ['000153', 'saab'],
  ['000154', 'rover'],
  ['000155', 'subaru'],
  ['000156', 'suzuki'],
  ['000160', 'alpina-bmw'],
  ['000161', 'audi'],
  ['000173', 'volkswagen-messico'],
  ['000180', 'aro'],
  ['000193', 'donkervoort'],
  ['000196', 'ginetta'],
  ['000197', 'zaz'],
  ['000216', 'volga'],
  ['000222', 'umm'],
  ['000224', 'uaz'],
  ['000225', 'tvr'],
  ['000240', 'puma-italia'],
  ['000243', 'panther'],
  ['000250', 'casalini'],
  ['000254', 'ligier'],
  ['000255', 'santana'],
  ['000267', 'dodge'],
  ['000277', 'caterham'],
  ['000323', 'ac'],
  ['000333', 'apal'],
  ['000397', 'talbot'],
  ['000416', 'aixam'],
  ['000422', 'jdm'],
  ['000433', 'lada'],
  ['000436', 'rayton-fissore'],
  ['000495', 'marcos'],
  ['000507', 'venturi'],
  ['000515', 'auverland'],
  ['000577', 'daewoo'],
  ['000598', 'middlebridge'],
  ['000651', 'luaz-volin'],
  ['000657', 'mahindra'],
  ['000700', 'amg'],
  ['000717', 'oto-melara'],
  ['000746', 'kia'],
  ['000766', 'microcar'],
  ['000772', 'pgo'],
  ['000776', 'iato'],
  ['000804', 'evante'],
  ['000848', 'maruti'],
  ['000892', 'yugo'],
  ['000901', 'omai'],
  ['000926', 'micro-vett'],
  ['000941', 'piaggio'],
  ['000979', 'ssangyong'],
  ['001024', 'acm'],
  ['001036', 'torpedo'],
  ['001058', 'de-la-chapelle'],
  ['001117', 'noble'],
  ['001362', 'biagini'],
  ['001421', 'asia-motors'],
  ['001504', 'bellier'],
  ['001522', 'bugatti'],
  ['001539', 'chatenet'],
  ['001599', 'epocar'],
  ['001859', 'citycar'],
  ['001938', 'mega'],
  ['001945', 'mg'],
  ['001960', 'mazzieri'],
  ['001962', 'lexus'],
  ['001970', 'infiniti'],
  ['002092', 'moke'],
  ['002130', 'tata'],
  ['002176', 'boxel'],
  ['002222', 'mini'],
  ['002259', 'smart'],
  ['002266', 'savel-erad'],
  ['002269', 'tasso'],
  ['002273', 'carletti'],
  ['002275', 'grecav'],
  ['002277', 'secma'],
  ['002285', 'iso'],
  ['002288', 'pagani-marca'],
  ['002291', 'valentini'],
  ['002293', 'town-life'],
  ['002297', 'feab'],
  ['002300', 'qvale'],
  ['002303', 'gem'],
  ['002309', 'cmc-carletti'],
  ['002311', 'effedi'],
  ['002318', 'iveco'],
  ['002345', 'ariel'],
  ['002359', 'maybach'],
  ['002394', 'start-lab'],
  ['002398', 'hummer'],
  ['002403', 'seca'],
  ['002405', 'corvette'],
  ['002408', 'saleen'],
  ['002409', 'abarth'],
  ['002706', 'green-company'],
  ['002709', 'italcar'],
  ['002710', 'giottiline'],
  ['002712', 'melex'],
  ['002713', 'meta'],
  ['002715', 'great-wall-motor'],
  ['002717', 'dr'],
  ['002718', 'giotti-victoria'],
  ['002719', 'shuanghuan'],
  ['002745', 'martin-motors'],
  ['002749', 'tesla'],
  ['002752', 'fisker'],
  ['002754', 'mclaren'],
  ['002766', 'mustang'],
  ['002767', 'mia-electric'],
  ['002770', 'tazzari-ev'],
  ['002772', 'biro'],
  ['002773', 'minauto'],
  ['002774', 'romeo-ferraris'],
  ['002775', 'ds'],
  ['002776', 'mazzanti'],
  ['002777', 'xindayang'],
  ['002779', 'dallara'],
  ['002780', 'alpine'],
  ['002781', 'milite'],
  ['002782', 'cupra'],
  ['002785', 'mpm-motors'],
  ['002786', 'haval'],
  ['002788', 'xev'],
  ['002790', 'goupil'],
  ['002791', 'evo'],
  ['002792', 'ducati-energia'],
  ['002794', 'nanocar'],
  ['002795', 'seres'],
  ['002796', 'lynk-co'],
  ['002798', 'zd'],
  ['002799', 'aiways'],
  ['002800', 'ineos'],
  ['002801', 'dfsk'],
  ['002802', 'eli'],
  ['002803', 'today-sunshine'],
  ['002804', 'polestar'],
  ['002805', 'regis'],
  ['002806', 'emc'],
]);
