import type { RootState } from '@reducers/index';
import { setPreventPopstate } from '@reducers/system';
import type { UserState } from '@reducers/user';
import { saveUserData, setDialogInfo } from '@reducers/user';
import { LoginInPlace, LoginTemplate, type PublicUser } from '@sbt-web/auth';
import { useViewport } from '@sbt-web/hooks';
import { captureException } from '@sentry/nextjs';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

interface StateProps {
  user: UserState;
}

interface DispatchProps {
  setDialogInfo: (opened: boolean, loginTemplate?: LoginTemplate) => void;
  saveUserData: (user: PublicUser) => void;
}

export type Props = StateProps & DispatchProps;

export const LIPWrapperComponent: React.FunctionComponent<Props> = ({
  user,
  setDialogInfo,
  saveUserData,
}) => {
  const { isMobile } = useViewport();

  const handleLoginEvent = useCallback(
    (evt: Event & { detail: { loginInvoker?: string } }) => {
      const templateName: string = Object.keys(LoginTemplate).filter(
        (k) =>
          evt.detail.loginInvoker &&
          k.toLowerCase() === evt.detail.loginInvoker.toLowerCase()
      )[0];

      const template =
        LoginTemplate[templateName as keyof typeof LoginTemplate];

      setDialogInfo(true, template);
    },
    [setDialogInfo]
  );

  useEffect(() => {
    // @ts-expect-error: The event is custom.
    window.addEventListener('subito:openLoginDialog', handleLoginEvent);

    return () => {
      // @ts-expect-error: The event is custom.
      window.removeEventListener('subito:openLoginDialog', handleLoginEvent);
    };
  }, [handleLoginEvent]);

  const handleLogin = useCallback(
    (userDetails: PublicUser | null): void => {
      if (userDetails === null) {
        captureException(
          new TypeError('User details are null after a login in place')
        );
        return;
      }

      setDialogInfo(false);
      saveUserData(userDetails);

      user.onLoginCallback?.(userDetails);
    },
    [saveUserData, setDialogInfo, user]
  );

  return (
    <LoginInPlace
      hashID="login-in-place"
      onCloseIntent={() => setDialogInfo(false)}
      onEnter={() => setDialogInfo(true)}
      onExit={() => setDialogInfo(false)}
      onLogin={handleLogin}
      isMobile={isMobile}
      reservedAreaBase={process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}
      template={user.dialogTemplate}
      visited={user.dialogOpened}
      enableNewPage
    />
  );
};

export const LIPWrapper = connect<
  StateProps,
  DispatchProps,
  unknown,
  RootState
>(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    setDialogInfo: (opened: boolean, loginTemplate?: LoginTemplate) => {
      dispatch(setDialogInfo(opened, loginTemplate));
      dispatch(setPreventPopstate(opened));
    },
    saveUserData: (user: PublicUser) => dispatch(saveUserData(user)),
  })
)(LIPWrapperComponent);
