import React from 'react';
import { runAdv } from '@client/components/Adv/GPT/sdkIntegration';
import type { ENV } from '@sbt-web/utils';
import { useViewport } from '@sbt-web/hooks';
import { AdItem } from '@sbt-web/networking';
import { AdUnitSize } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';

let neverLoaded = false;
let promiseResolver: (value: boolean | PromiseLike<boolean>) => void;
export const newAdvEndedPromise = new Promise((r) => (promiseResolver = r));

export const useAdvDetails = function (
  clientReady: boolean,
  ad: AdItem,
  advDetailGalleryToggle?: boolean,
  advDesktopBelowGalleryToggle?: boolean,
  skyscraperSizes?: Array<AdUnitSize>
) {
  const [dataLayerReady, setDataLayerReady] = React.useState(false);
  const { isDesktop } = useViewport();
  React.useEffect(() => {
    if (
      dataLayerReady &&
      !neverLoaded &&
      clientReady === true &&
      skyscraperSizes
    ) {
      neverLoaded = true;
      const runAdvPromise = runAdv(
        'adview',
        ad.category.id,
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
        ad,
        advDetailGalleryToggle,
        undefined,
        skyscraperSizes,
        advDesktopBelowGalleryToggle
      );
      runAdvPromise.then(() => promiseResolver(true));
    }
  }, [
    dataLayerReady,
    clientReady,
    isDesktop,
    ad,
    advDetailGalleryToggle,
    skyscraperSizes,
    advDesktopBelowGalleryToggle,
  ]);

  return {
    setDataLayerReadyForAdv: setDataLayerReady,
  };
};
